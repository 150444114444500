import { ExtendedFirestoreInstance } from "react-redux-firebase";
import notificationError from "../../../system-components/toasters/notificationError";
import { INVENTORY_COLLECTION, PARTS_COLLECTION, SERVICE_ITEMS_COLLECTION } from "../../../utils/models/collections/collectionConstants";
import { InventoryItem } from "../../../interfaces/inventoryItem";
import { Part } from "../../../interfaces/part";

export function inventoryWrites(
  { firestore }: { firestore: ExtendedFirestoreInstance },
  orgPrefix: string
) {
  if (!orgPrefix)
    return notificationError(
      "Organization error",
      "Cannot determine organization"
    );
  const ref = firestore.collection(`${orgPrefix}${INVENTORY_COLLECTION}`);

  async function createInventory(payload: Partial<InventoryItem>) {

    // First check to make sure there is not another customer with the same customer display name:
    const inventoryItemExists = await ref.where('name', '==', payload.name).get();
    if (!inventoryItemExists.empty) {
      const error = new Error('Inventory exists');
      throw error;
    }

    const newRef = firestore
      .collection(`${orgPrefix}${INVENTORY_COLLECTION}`)
      .doc();
    return await newRef
      .set({
        id: newRef.id,
        ...payload,
      })
      .then(() => {
        return newRef.id;
      });
  }
  async function updateInventory(id: string, payload: Partial<InventoryItem>) {

    // First check to make sure there is not another customer with the same customer display name:
    const inventoryItems = await ref.where('name', '==', payload.name).get();

    if (!inventoryItems.empty) {
      const exists = inventoryItems.docs.some(doc => doc.id !== id);
      if (exists) {
        const error = new Error('Inventory exists');
        throw error;
      }
    }

    return await ref.doc(id).update(payload);
  }
  async function deleteInventory(id: string) {
    return await ref.doc(id).delete();
  }
  return {
    createInventory,
    updateInventory,
    deleteInventory,
  };
}

export function partWrites(
  { firestore }: { firestore: ExtendedFirestoreInstance },
  orgPrefix: string
) {
  if (!orgPrefix)
    return notificationError(
      "Organization error",
      "Cannot determine organization"
    );
  const ref = firestore.collection(`${orgPrefix}${PARTS_COLLECTION}`);

  async function createPart(payload: Partial<Part>) {
    const newRef = firestore
      .collection(`${orgPrefix}${PARTS_COLLECTION}`)
      .doc();
    return await newRef
      .set({
        id: newRef.id,
        ...payload,
      })
      .then(() => {
        return newRef.id;
      });
  }
  async function updatePart(id: string, payload: Partial<Part>) {
    return await ref.doc(id).update(payload);
  }
  async function deletePart(id: string) {
    return await ref.doc(id).delete();
  }
  return {
    createPart,
    updatePart,
    deletePart,
  };
}


export function serviceItemWrites(
  { firestore }: { firestore: ExtendedFirestoreInstance },
  orgPrefix: string
) {
  if (!orgPrefix)
    return notificationError(
      "Organization error",
      "Cannot determine organization"
    );
  const ref = firestore.collection(`${orgPrefix}${SERVICE_ITEMS_COLLECTION}`);

  async function createServiceItem(payload: Partial<InventoryItem>) {
    const newRef = firestore
      .collection(`${orgPrefix}${SERVICE_ITEMS_COLLECTION}`)
      .doc();
    return await newRef
      .set({
        id: newRef.id,
        ...payload,
      })
      .then(() => {
        return newRef.id;
      });
  }
  async function updateServiceItem(id: string, payload: Partial<InventoryItem>) {
    return await ref.doc(id).update(payload);
  }
  async function deleteServiceItem(id: string) {
    return await ref.doc(id).delete();
  }
  return {
    createServiceItem,
    updateServiceItem,
    deleteServiceItem,
  };
}