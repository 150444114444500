import React from 'react';
import { Box, Accordion, AccordionSummary, AccordionDetails, Typography, Stack, Tooltip, useTheme, alpha } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Invoice } from '../../../app/interfaces/invoice';

interface MadeByKitSectionProps {
  invoice: Invoice | undefined;
  accordianSx: any;
  accordianSummarySx: any;
  madeByKitAccordianExpanded: boolean;
  setMadeByKitAccordianExpanded: (expanded: boolean) => void;
  responsiveFontSize: string | number;
}

export const MadeByKitSection: React.FC<MadeByKitSectionProps> = ({
  invoice,
  accordianSx,
  accordianSummarySx,
  madeByKitAccordianExpanded,
  setMadeByKitAccordianExpanded,
  responsiveFontSize,
}) => {
  const theme = useTheme();

  if (!invoice?.madeByKit) return null;

  return (
    <Box sx={{ borderRadius: 2, my: 2 }} bgcolor={theme.palette.background.default}>
      <Accordion
        disableGutters={true}
        sx={{
          ...accordianSx,
        }}
        defaultExpanded={madeByKitAccordianExpanded}
        onChange={() => setMadeByKitAccordianExpanded(!madeByKitAccordianExpanded)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id="made-by-kit-accordian"
          sx={{
            ...accordianSummarySx,
            borderRadius: madeByKitAccordianExpanded ? "8px 8px 0 0" : "8px",
          }}
        >
          <Stack direction={"row"} gap={0.5}>
            <Typography fontWeight={"bold"} fontSize={responsiveFontSize}>
              Made By Kit
            </Typography>
          </Stack>
        </AccordionSummary>

        <AccordionDetails>
          <>
            <Typography fontWeight={"bold"} gutterBottom fontSize={responsiveFontSize} color={theme.palette.text.primary}>
              This invoice was made by Kit. It was matched to the email body and the items, customer, and other details were added to the invoice. Double check the items and make sure they are correct.
            </Typography>

            <Typography fontWeight={"bold"} gutterBottom fontSize={responsiveFontSize} color={theme.palette.text.primary}>
              Original Email Body
            </Typography>
            {invoice?.originalData?.emailBody && (
              <Box sx={{ 
                mb: 3, 
                p: 2, 
                bgcolor: alpha(theme.palette.background.paper, 0.5),
                borderRadius: 1,
                border: `1px solid ${alpha(theme.palette.divider, 0.2)}`,
                height: '400px',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                  background: alpha(theme.palette.background.paper, 0.1),
                  borderRadius: '4px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: alpha(theme.palette.text.secondary, 0.2),
                  borderRadius: '4px',
                  '&:hover': {
                    background: alpha(theme.palette.text.secondary, 0.3),
                  },
                },
              }}>
                <Box sx={{
                  whiteSpace: 'pre-wrap',
                  wordBreak: 'break-word',
                }}>
                  {(() => {
                    if (!invoice?.originalData?.emailBody) return null;
                    
                    const emailBody = invoice.originalData.emailBody;
                    const matches = invoice.originalData?.matchedResults?.matches || [];
                    const unmatchedRequests = invoice.originalData?.matchedResults?.unmatchedRequests || [];
                    const initialResults = invoice.originalData?.initialResults;
                    
                    // Create a map of positions to highlights
                    const positionMap = new Map<number, {
                      length: number;
                      type: 'item' | 'quantity' | 'address' | 'phone';
                      tooltip: string;
                      isMatched?: boolean;
                      confidence?: number;
                      matchedName?: string;
                    }>();

                    // Add item matches
                    matches.forEach(m => {
                      const searchTerms = m.matchedItem.searchTerms || [m.requestedItem.name];
                      searchTerms.forEach(term => {
                        const index = emailBody.toLowerCase().indexOf(term.toLowerCase());
                        if (index !== -1) {
                          positionMap.set(index, {
                            length: term.length,
                            type: 'item',
                            isMatched: true,
                            confidence: m.confidence,
                            matchedName: m.matchedItem.name,
                            tooltip: `Matched to: ${m.matchedItem.name} (Confidence: ${(m.confidence * 100).toFixed(1)}%)`
                          });
                        }
                      });
                    });

                    // Add unmatched items
                    unmatchedRequests.forEach(u => {
                      const index = emailBody.toLowerCase().indexOf(u.name.toLowerCase());
                      if (index !== -1) {
                        positionMap.set(index, {
                          length: u.name.length,
                          type: 'item',
                          isMatched: false,
                          tooltip: 'Unmatched item'
                        });
                      }
                    });

                    /* Commenting out quantity highlights for now
                    // Add quantities
                    matches.forEach(m => {
                      const qtyStr = String(m.requestedItem.quantity);
                      const index = emailBody.indexOf(qtyStr);
                      if (index !== -1) {
                        positionMap.set(index, {
                          length: qtyStr.length,
                          type: 'quantity',
                          tooltip: `Quantity for ${m.requestedItem.name}`
                        });
                      }
                    });
                    */

                    // Add all fields from initialResults
                    if (initialResults) {
                      // Add email if found
                      if (initialResults.email) {
                        const index = emailBody.indexOf(initialResults.email);
                        if (index !== -1) {
                          positionMap.set(index, {
                            length: initialResults.email.length,
                            type: 'quantity',
                            tooltip: 'Email Address'
                          });
                        }
                      }

                      // Add dates if found
                      if (initialResults.endDate) {
                        const index = emailBody.indexOf(initialResults.endDate);
                        if (index !== -1) {
                          positionMap.set(index, {
                            length: initialResults.endDate.length,
                            type: 'quantity',
                            tooltip: 'End Date'
                          });
                        }
                      }

                      if (initialResults.eventDate) {
                        const index = emailBody.indexOf(initialResults.eventDate);
                        if (index !== -1) {
                          positionMap.set(index, {
                            length: initialResults.eventDate.length,
                            type: 'quantity',
                            tooltip: 'Event Date'
                          });
                        }
                      }

                      // Add address components if found
                      if (initialResults.address) {
                        const { address } = initialResults;
                        if (address.full) {
                          const index = emailBody.indexOf(address.full);
                          if (index !== -1) {
                            positionMap.set(index, {
                              length: address.full.length,
                              type: 'quantity',
                              tooltip: 'Full Address'
                            });
                          }
                        }
                        if (address.street) {
                          const index = emailBody.indexOf(address.street);
                          if (index !== -1) {
                            positionMap.set(index, {
                              length: address.street.length,
                              type: 'quantity',
                              tooltip: 'Street Address'
                            });
                          }
                        }
                        if (address.city) {
                          const index = emailBody.indexOf(address.city);
                          if (index !== -1) {
                            positionMap.set(index, {
                              length: address.city.length,
                              type: 'quantity',
                              tooltip: 'City'
                            });
                          }
                        }
                        if (address.state) {
                          const index = emailBody.indexOf(address.state);
                          if (index !== -1) {
                            positionMap.set(index, {
                              length: address.state.length,
                              type: 'quantity',
                              tooltip: 'State'
                            });
                          }
                        }
                        if (address.zip) {
                          const index = emailBody.indexOf(address.zip);
                          if (index !== -1) {
                            positionMap.set(index, {
                              length: address.zip.length,
                              type: 'quantity',
                              tooltip: 'ZIP Code'
                            });
                          }
                        }
                      }

                      // Add internal notes if found
                      if (initialResults.internalNotes) {
                        const index = emailBody.indexOf(initialResults.internalNotes);
                        if (index !== -1) {
                          positionMap.set(index, {
                            length: initialResults.internalNotes.length,
                            type: 'quantity',
                            tooltip: 'Internal Notes'
                          });
                        }
                      }
                    }

                    // Add phone number if found
                    if (initialResults?.phone) {
                      const phoneDigits = initialResults.phone.replace(/\D/g, '');
                      let startIndex = 0;
                      while (startIndex < emailBody.length) {
                        const chunk = emailBody.substr(startIndex, 20); // Look at chunks of 20 chars
                        const digits = chunk.replace(/\D/g, '');
                        if (digits.includes(phoneDigits)) {
                          const index = emailBody.indexOf(chunk);
                          if (index !== -1) {
                            positionMap.set(index, {
                              length: chunk.length,
                              type: 'phone',
                              tooltip: 'Phone Number'
                            });
                            break;
                          }
                        }
                        startIndex += 10;
                      }
                    }

                    // Convert positions to sorted array
                    const sortedPositions = Array.from(positionMap.entries())
                      .sort(([a], [b]) => a - b);

                    let lastIndex = 0;
                    const parts: JSX.Element[] = [];

                    // Build the parts array
                    sortedPositions.forEach(([position, highlight]) => {
                      if (position > lastIndex) {
                        parts.push(
                          <Typography 
                            key={`text-${lastIndex}`} 
                            component="span" 
                            fontSize={responsiveFontSize} 
                            color={theme.palette.text.secondary}
                          >
                            {emailBody.substring(lastIndex, position)}
                          </Typography>
                        );
                      }

                      const highlightStyle = (() => {
                        switch (highlight.type) {
                          case 'item':
                            return {
                              backgroundColor: highlight.isMatched 
                                ? alpha(theme.palette.success.main, 0.1)
                                : alpha(theme.palette.error.main, 0.1),
                              color: highlight.isMatched 
                                ? theme.palette.success.main
                                : theme.palette.error.main,
                            };
                          case 'quantity':
                            return {
                              backgroundColor: alpha(theme.palette.info.main, 0.1),
                              color: theme.palette.info.main,
                            };
                          case 'phone':
                            return {
                              backgroundColor: alpha(theme.palette.secondary.main, 0.1),
                              color: theme.palette.secondary.main,
                            };
                          default:
                            return {};
                        }
                      })();

                      parts.push(
                        <Tooltip 
                          key={`highlight-${position}`}
                          title={highlight.tooltip}
                        >
                          <Typography
                            component="span"
                            fontSize={responsiveFontSize}
                            sx={{
                              ...highlightStyle,
                              px: 0.5,
                              borderRadius: 0.5,
                            }}
                          >
                            {emailBody.substr(position, highlight.length)}
                          </Typography>
                        </Tooltip>
                      );

                      lastIndex = position + highlight.length;
                    });

                    // Add remaining text
                    if (lastIndex < emailBody.length) {
                      parts.push(
                        <Typography 
                          key={`text-${lastIndex}`} 
                          component="span" 
                          fontSize={responsiveFontSize} 
                          color={theme.palette.text.secondary}
                        >
                          {emailBody.substring(lastIndex)}
                        </Typography>
                      );
                    }

                    return parts;
                  })()}
                </Box>
              </Box>
            )}

            <Typography fontWeight={"bold"} gutterBottom fontSize={responsiveFontSize} color={theme.palette.text.primary}>
              Requested Items
            </Typography>
            {invoice?.originalData?.matchedResults?.matches?.map((match, index) => (
              <Box key={index} sx={{ mb: 2 }}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography fontSize={responsiveFontSize} color={theme.palette.text.primary}>
                    {match.requestedItem.name} (Qty: {match.requestedItem.quantity})
                  </Typography>
                  <Typography fontSize={responsiveFontSize} color={theme.palette.success.main}>
                    → {match.matchedItem.name}
                  </Typography>
                  <Typography fontSize={responsiveFontSize} color={theme.palette.text.secondary}>
                    (Confidence: {(match.confidence * 100).toFixed(1)}%)
                  </Typography>
                </Stack>
                {match.notes && (
                  <Typography fontSize={responsiveFontSize} color={theme.palette.text.secondary} sx={{ ml: 2 }}>
                    Note: {match.notes}
                  </Typography>
                )}
              </Box>
            ))}

            {Boolean(invoice?.originalData?.matchedResults?.unmatchedRequests?.length) && (
              <>
                <Typography fontWeight={"bold"} gutterBottom fontSize={responsiveFontSize} color={theme.palette.error.main} sx={{ mt: 2 }}>
                  Unmatched Items
                </Typography>
                {invoice?.originalData?.matchedResults?.unmatchedRequests?.map((item, index) => (
                  <Typography key={index} fontSize={responsiveFontSize} color={theme.palette.error.main}>
                    • {item.name} (Qty: {item.quantity})
                  </Typography>
                ))}
              </>
            )}
          </>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};