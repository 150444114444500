import { useEffect, useState } from "react";
import { INVOICE_DRAFT } from "../../app/utils/models/modelConstants/modelConstants";
import { capitalize } from 'lodash';
import { useSelector } from "react-redux";
import { authSelector } from "./authSlice";

export function useInvoiceHeader(invoice) {

  const { orgData } = useSelector(authSelector);

  const [title, setTitle] = useState("");
  const [invoiceNumbers, setInvoiceNumbers] = useState({
    hasError: false,
    adelie: null,
    qbo: null,
    errorLabel: "",
  });

  useEffect(() => {

    if (!invoice) { 
      return setTitle("New Order");
    }

    setTitle(`#${invoice?.invoiceNumber} (${capitalize(invoice?.type)})`);

    if (invoice?.type === INVOICE_DRAFT) return;

    if (!orgData) return;

    const qboEnabled = orgData?.integrations?.qbOnline || orgData?.integrations?.qbDesktop;

    if (!qboEnabled) return;
    console.log(matchNums(invoice));
    if (matchNums(invoice).isMatch) return;
    return setInvoiceNumbers({
      hasError: true,
      adelie: matchNums(invoice).adelie,
      qbo: matchNums(invoice).qbo,
      errorLabel: `RentKit: ${matchNums(invoice).adelie} | Quickbooks: ${
        matchNums(invoice).qbo
      }`,
    });

  }, [invoice]);
  
  return {
    title,
    invoiceNumbers,
  };
}

const matchNums = (invoice) => {
  const adelie = `${invoice?.invoiceNumber}` ?? "No RentKit Invoice Number";
  const qbo = invoice?.qboInvoiceNumber ?? "No Quickbooks Invoice Number";
  return {
    isMatch: adelie === qbo,
    adelie: `${adelie}`,
    qbo: `${qbo}`,
  };
};
