export const en = {
  common: {
    or: 'or',
    cancel: 'Cancel',
    reset: 'Reset',
    save: 'Save',
    search: 'Search',
    edit: 'Edit',
    remove: 'Remove',
    new: 'New',
    export: 'Export to Excel',
    noDataToExport: 'No data to export',
    import: 'Import',
    discard: 'Discard',
    yes: 'Yes',
    no: 'No',
    pause: 'Pause',
    areYouSure: 'Are you sure?',
    view: 'View',
    destroy: 'Delete',
    mustSelectARow: 'Must select a row',
    filters: 'Filters',
    noneSpecified: 'None Specified',
  },

  app: {
    title: 'RentKit',
  },
  pdf: {
    updated: 'Updated',
    dueBy: 'Payment Due By',
    rentalStart: 'Rental Start',
    rentalEnd: 'Rental End',
    deliveryFee: 'Delivery Fee',
    discount: 'Discount',
    subtotal: 'Subtotal',
    taxes: 'Taxes',
    total: 'Total',
    amountPaid: 'Amount Paid',
    remainingBalance: 'Remaining Balance',
    receiveReturnDetails: 'Receive and Return Details',
    customerReceivesVia: 'Customer Receives Items Via',
    returnedVia: 'Items Returned Via',
    address: 'Address',
    timeRange: 'Time(Range)',
    columnItem: 'Item',
    columnDescription: 'Description',
    colQty: 'Qty',
    rate: 'Rate',
    duration: 'Duration',
    venue: 'Venue',
    contact: 'Contact',
    delivery: 'Delivery',
    customerPickup: 'Customer Pickup',
    pickup: 'Pickup',
    customerDropOff: 'Customer Dropoff',
    additionalNotes: 'Additional Notes',
    invoiceString: 'Invoice',
    estimateString: 'Estimate',
  },
  availabilityPage: {
    title: 'Available Inventory',
    selectDates: 'Select rental dates to begin',
  },

  inventory: {
    search: 'Search Inventory',
    filters: 'Filters',
  },

  invoices: {
    sales: 'Sales',
    invoiceSearchPlaceholder: 'Search customer, email, or invoice #',
    newOrder: 'New Order',
    type: 'Type',
    customer: 'Customer',
    rentalStart: 'Rental Start',
    rentalEnd: 'Rental End',
    eventDate: 'Event Date',
    lastUpdated: 'Last Updated',
    total: 'Total',
    balance: 'Balance',
    days: 'Days',
    email: 'Email',
    status: 'Status',
  },

  sidebar: {
    availability: 'Check Availability',
    invoices: 'Make The Sale',
    prepare: 'Prepare The Items',
    routes: 'Manage Delivery/Pickup',
    reports: {
      title: "See How You're Doing",
      sales: 'Sales Report',
      tax: 'Tax Report',
      usage: 'Usage Report',
      depreciation: 'Depreciation Report',
    },
    admin: {
      title: 'Admin',
      users: 'Users',
      inventory: 'Inventory',
      customers: 'Customers',
      venues: 'Venues',
      trucks: 'Trucks',
      integrations: 'Integrations',
    },
    settings: {
      title: 'Settings',
      general: 'General',
      email: 'Email',
      embeddedCheckout: 'Embedded Checkout',
    },
  },

  entities: {
    adelieInventoryItem: {
      name: 'adelieInventoryItem',
      label: 'AdelieInventoryItems',
      menu: 'AdelieInventoryItems',
      exporterFileName: 'adelieInventoryItem_export',
      list: {
        menu: 'AdelieInventoryItems',
        title: 'AdelieInventoryItems',
      },
      create: {
        success: 'AdelieInventoryItem successfully saved',
      },
      update: {
        success: 'AdelieInventoryItem successfully saved',
      },
      destroy: {
        success: 'AdelieInventoryItem successfully deleted',
      },
      destroyAll: {
        success: 'AdelieInventoryItem(s) successfully deleted',
      },
      edit: {
        title: 'Edit AdelieInventoryItem',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        stockRange: 'Stock',
        stock: 'Stock',
        turnaroundRange: 'Turnaround',
        turnaround: 'Turnaround',
        externalItemId: 'ExternalItemId',
        userIdRange: 'UserId',
        userId: 'UserId',
        categoryIdRange: 'CategoryId',
        categoryId: 'CategoryId',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New AdelieInventoryItem',
      },
      view: {
        title: 'View AdelieInventoryItem',
      },
      importer: {
        title: 'Import AdelieInventoryItems',
        fileName: 'adelieInventoryItem_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    adelieInventoryCategory: {
      name: 'adelieInventoryCategory',
      label: 'AdelieInventoryCategories',
      menu: 'AdelieInventoryCategories',
      exporterFileName: 'adelieInventoryCategory_export',
      list: {
        menu: 'AdelieInventoryCategories',
        title: 'AdelieInventoryCategories',
      },
      create: {
        success: 'AdelieInventoryCategory successfully saved',
      },
      update: {
        success: 'AdelieInventoryCategory successfully saved',
      },
      destroy: {
        success: 'AdelieInventoryCategory successfully deleted',
      },
      destroyAll: {
        success: 'AdelieInventoryCategory(s) successfully deleted',
      },
      edit: {
        title: 'Edit AdelieInventoryCategory',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        userIdRange: 'UserId',
        userId: 'UserId',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New AdelieInventoryCategory',
      },
      view: {
        title: 'View AdelieInventoryCategory',
      },
      importer: {
        title: 'Import AdelieInventoryCategories',
        fileName: 'adelieInventoryCategory_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    adelieInvoice: {
      name: 'adelieInvoice',
      label: 'AdelieInvoices',
      menu: 'AdelieInvoices',
      exporterFileName: 'adelieInvoice_export',
      list: {
        menu: 'AdelieInvoices',
        title: 'AdelieInvoices',
      },
      create: {
        success: 'AdelieInvoice successfully saved',
      },
      update: {
        success: 'AdelieInvoice successfully saved',
      },
      destroy: {
        success: 'AdelieInvoice successfully deleted',
      },
      destroyAll: {
        success: 'AdelieInvoice(s) successfully deleted',
      },
      edit: {
        title: 'Edit AdelieInvoice',
      },
      fields: {
        id: 'Id',
        userIdRange: 'UserId',
        userId: 'UserId',
        invoiceNumber: 'InvoiceNumber',
        externalInvoiceId: 'ExternalInvoiceId',
        typedShipDateTime: 'TypedShipDateTime',
        typedReturnDateTime: 'TypedReturnDateTime',
        shipDateTimeRange: 'ShipDateTime',
        shipDateTime: 'ShipDateTime',
        returnDateTimeRange: 'ReturnDateTime',
        returnDateTime: 'ReturnDateTime',
        dueDateRange: 'DueDate',
        dueDate: 'DueDate',
        invoiceDateRange: 'InvoiceDate',
        invoiceDate: 'InvoiceDate',
        customerIdRange: 'CustomerId',
        customerId: 'CustomerId',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New AdelieInvoice',
      },
      view: {
        title: 'View AdelieInvoice',
      },
      importer: {
        title: 'Import AdelieInvoices',
        fileName: 'adelieInvoice_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    adelieInvoiceItem: {
      name: 'adelieInvoiceItem',
      label: 'AdelieInvoiceItems',
      menu: 'AdelieInvoiceItems',
      exporterFileName: 'adelieInvoiceItem_export',
      list: {
        menu: 'AdelieInvoiceItems',
        title: 'AdelieInvoiceItems',
      },
      create: {
        success: 'AdelieInvoiceItem successfully saved',
      },
      update: {
        success: 'AdelieInvoiceItem successfully saved',
      },
      destroy: {
        success: 'AdelieInvoiceItem successfully deleted',
      },
      destroyAll: {
        success: 'AdelieInvoiceItem(s) successfully deleted',
      },
      edit: {
        title: 'Edit AdelieInvoiceItem',
      },
      fields: {
        id: 'Id',
        userIdRange: 'UserId',
        userId: 'UserId',
        description: 'Description',
        quantityRange: 'Quantity',
        quantity: 'Quantity',
        rateRange: 'Rate',
        rate: 'Rate',
        invoiceIdRange: 'InvoiceId',
        invoiceId: 'InvoiceId',
        itemIdRange: 'ItemId',
        itemId: 'ItemId',
        isLoaded: 'IsLoaded',
        lineIdRange: 'LineId',
        lineId: 'LineId',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New AdelieInvoiceItem',
      },
      view: {
        title: 'View AdelieInvoiceItem',
      },
      importer: {
        title: 'Import AdelieInvoiceItems',
        fileName: 'adelieInvoiceItem_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    adelieCustomer: {
      name: 'adelieCustomer',
      label: 'AdelieCustomers',
      menu: 'AdelieCustomers',
      exporterFileName: 'adelieCustomer_export',
      list: {
        menu: 'AdelieCustomers',
        title: 'AdelieCustomers',
      },
      create: {
        success: 'AdelieCustomer successfully saved',
      },
      update: {
        success: 'AdelieCustomer successfully saved',
      },
      destroy: {
        success: 'AdelieCustomer successfully deleted',
      },
      destroyAll: {
        success: 'AdelieCustomer(s) successfully deleted',
      },
      edit: {
        title: 'Edit AdelieCustomer',
      },
      fields: {
        id: 'Id',
        userIdRange: 'UserId',
        userId: 'UserId',
        externalCustomerId: 'ExternalCustomerId',
        fullName: 'FullName',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New AdelieCustomer',
      },
      view: {
        title: 'View AdelieCustomer',
      },
      importer: {
        title: 'Import AdelieCustomers',
        fileName: 'adelieCustomer_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },
  },

  auth: {
    tenants: 'Workspaces',
    profile: {
      title: 'Profile',
      success: 'Profile successfully updated',
    },
    createAnAccount: 'Create an account',
    rememberMe: 'Remember me',
    forgotPassword: 'Forgot password',
    signin: 'Sign in',
    signup: 'Sign up',
    signout: 'Sign out',
    alreadyHaveAnAccount: 'Already have an account? Sign in.',
    signinWithAnotherAccount: 'Sign in with another account',
    passwordChange: {
      title: 'Change Password',
      success: 'Password successfully changed',
      mustMatch: 'Passwords must match',
    },
    emailUnverified: {
      message: `Please confirm your email at <strong>{0}</strong> to continue.`,
      submit: `Resend email verification`,
    },
    emptyPermissions: {
      message: `You have no permissions yet. Wait for the admin to grant you privileges.`,
    },
    passwordResetEmail: {
      message: 'Send password reset email',
      error: `Email not recognized`,
    },
    passwordReset: {
      message: 'Reset password',
    },
    emailAddressVerificationEmail: {
      error: `Email not recognized`,
    },
    verificationEmailSuccess: `Verification email successfully sent`,
    passwordResetEmailSuccess: `Password reset email successfully sent`,
    passwordResetSuccess: `Password successfully changed`,
    verifyEmail: {
      success: 'Email successfully verified.',
      message: 'Just a moment, your email is being verified...',
    },
  },

  tenant: {
    name: 'tenant',
    label: 'Workspaces',
    menu: 'Workspaces',
    list: {
      menu: 'Workspaces',
      title: 'Workspaces',
    },
    create: {
      button: 'Create Workspace',
      success: 'Workspace successfully saved',
    },
    update: {
      success: 'Workspace successfully saved',
    },
    destroy: {
      success: 'Workspace successfully deleted',
    },
    destroyAll: {
      success: 'Workspace(s) successfully deleted',
    },
    edit: {
      title: 'Edit Workspace',
    },
    fields: {
      id: 'Id',
      name: 'Name',
      url: 'URL',
      tenantName: 'Workspace Name',
      tenantId: 'Workspace',
      tenantUrl: 'Workspace URL',
      plan: 'Plan',
    },
    enumerators: {},
    new: {
      title: 'New Workspace',
    },
    invitation: {
      view: 'View Invitations',
      invited: 'Invited',
      accept: 'Accept Invitation',
      decline: 'Decline Invitation',
      declined: 'Invitation successfully declined',
      acceptWrongEmail: 'Accept Invitation With This Email',
    },
    select: 'Select Workspace',
    validation: {
      url: 'Your workspace URL can only contain lowercase letters, numbers and dashes (and must start with a letter or number).',
    },
  },

  roles: {
    admin: {
      label: 'Admin',
      description: 'Full access to all resources',
    },
    office: {
      label: 'Office',
      description: 'Access to Most Resources',
    },
    warehouse: {
      label: 'Warehouse',
      description: 'Restricted Access',
    },
  },

  user: {
    invite: 'Invite',
    title: 'Users',
    menu: 'Users',
    fields: {
      id: 'Id',
      avatars: 'Avatar',
      email: 'Email',
      emails: 'Email(s)',
      fullName: 'Name',
      firstName: 'First Name',
      lastName: 'Last Name',
      status: 'Status',
      phoneNumber: 'Phone Number',
      role: 'Role',
      createdAt: 'Created at',
      updatedAt: 'Updated at',
      roleUser: 'Role/User',
      roles: 'Roles',
      createdAtRange: 'Created at',
      password: 'Password',
      rememberMe: 'Remember me',
      oldPassword: 'Old Password',
      newPassword: 'New Password',
      newPasswordConfirmation: 'New Password Confirmation',
    },
    validations: {
      // eslint-disable-next-line
      email: 'Email ${value} is invalid',
    },
    disable: 'Disable',
    enable: 'Enable',
    doAddSuccess: 'User(s) successfully saved',
    doUpdateSuccess: 'User successfully saved',
    status: {
      active: 'Active',
      invited: 'Invited',
      'empty-permissions': 'Waiting for Permissions',
    },
    exporterFileName: 'users_export',
    doDestroySuccess: 'User successfully deleted',
    doDestroyAllSelectedSuccess: 'User(s) successfully deleted',
    edit: {
      title: 'Edit User',
    },
    new: {
      title: 'New User(s)',
      titleModal: 'New User',
      emailsHint: 'Separate multiple email addresses using the comma character.',
    },
    view: {
      title: 'View User',
      activity: 'Activity',
    },
    importer: {
      title: 'Import Users',
      fileName: 'users_import_template',
      hint: 'Files/Images columns must be the URLs of the files separated by space. Relationships must be the ID of the referenced records separated by space. Roles must be the role ids separated by space.',
    },
    errors: {
      userAlreadyExists: 'User with this email already exists',
      userNotFound: 'User not found',
      disablingHimself: `You can't disable yourself`,
      revokingOwnPermission: `You can't revoke your own admin permission`,
    },
  },

  plan: {
    menu: 'Plans',
    title: 'Plans',

    free: {
      label: 'Free',
      price: '$0',
    },
    growth: {
      label: 'Growth',
      price: '$10',
    },
    enterprise: {
      label: 'Enterprise',
      price: '$50',
    },

    pricingPeriod: '/month',
    current: 'Current Plan',
    subscribe: 'Subscribe',
    manage: 'Manage Subscription',
    cancelAtPeriodEnd: 'This plan will be canceled at the end of the period.',
    somethingWrong: 'There is something wrong with your subscription. Please go to manage subscription for more details.',
    notPlanUser: `You are not the manager of this subscription.`,
  },

  auditLog: {
    menu: 'Audit Logs',
    title: 'Audit Logs',
    exporterFileName: 'audit_log_export',
    entityNamesHint: 'Separate multiple entities using the comma character.',
    fields: {
      id: 'Id',
      timestampRange: 'Period',
      entityName: 'Entity',
      entityNames: 'Entities',
      entityId: 'Entity ID',
      action: 'Action',
      values: 'Values',
      timestamp: 'Date',
      createdByEmail: 'User Email',
    },
  },
  settings: {
    title: 'Settings',
    menu: 'Settings',
    save: {
      success: 'Settings successfully saved. The page will reload in {0} seconds for changes to take effect.',
    },
    fields: {
      theme: 'Theme',
      logos: 'Logo',
      backgroundImages: 'Background Image',
    },
    colors: {
      default: 'Default',
      cyan: 'Cyan',
      'geek-blue': 'Geek Blue',
      gold: 'Gold',
      lime: 'Lime',
      magenta: 'Magenta',
      orange: 'Orange',
      'polar-green': 'Polar Green',
      purple: 'Purple',
      red: 'Red',
      volcano: 'Volcano',
      yellow: 'Yellow',
    },
  },
  dashboard: {
    menu: 'Dashboard',
    message: `This page uses fake data for demonstration purposes only. You can edit it at frontend/view/dashboard/DashboardPage.ts.`,
    charts: {
      day: 'Day',
      red: 'Red',
      green: 'Green',
      yellow: 'Yellow',
      grey: 'Grey',
      blue: 'Blue',
      orange: 'Orange',
      months: {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
      },
      eating: 'Eating',
      drinking: 'Drinking',
      sleeping: 'Sleeping',
      designing: 'Designing',
      coding: 'Coding',
      cycling: 'Cycling',
      running: 'Running',
      customer: 'Customer',
    },
  },
  errors: {
    backToHome: 'Back to home',
    403: `Sorry, you don't have access to this page`,
    404: 'Sorry, the page you visited does not exist',
    500: 'Sorry, the server is reporting an error',
    429: 'Too many requests. Please try again later.',
    forbidden: {
      message: 'Forbidden',
    },
    validation: {
      message: 'An error occurred',
    },
    defaultErrorMessage: 'Ops, an error occurred',
  },
  // See https://github.com/jquense/yup#using-a-custom-locale-dictionary
  /* eslint-disable */
  /* eslint-disable */
  fileUploader: {
    upload: 'Upload',
    image: 'You must upload an image',
    size: 'File is too big. Max allowed size is {0}',
    formats: `Invalid format. Must be one of: {0}.`,
  },
  importer: {
    line: 'Line',
    status: 'Status',
    pending: 'Pending',
    imported: 'Imported',
    error: 'Error',
    total: `{0} imported, {1} pending and {2} with error`,
    importedMessage: `Processed {0} of {1}.`,
    noNavigateAwayMessage: 'Do not navigate away from this page or import will be stopped.',
    completed: {
      success: 'Import completed. All rows were successfully imported.',
      someErrors: 'Processing completed, but some rows were unable to be imported.',
      allErrors: 'Import failed. There are no valid rows.',
    },
    form: {
      downloadTemplate: 'Download the template',
      hint: 'Click or drag the file to this area to continue',
    },
    list: {
      discardConfirm: 'Are you sure? Non-imported data will be lost.',
    },
    errors: {
      invalidFileEmpty: 'The file is empty',
      invalidFileExcel: 'Only excel (.xlsx) files are allowed',
      invalidFileUpload: 'Invalid file. Make sure you are using the last version of the template.',
      importHashRequired: 'Import hash is required',
      importHashExistent: 'Data has already been imported',
    },
  },

  autocomplete: {
    loading: 'Loading...',
  },

  imagesViewer: {
    noImage: 'No image',
  },
};

export default en;
