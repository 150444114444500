import React from 'react';
import { Stack, Typography, Chip, Tooltip, Divider } from '@mui/material';
import { useResponsiveFontSize } from '../../../app/hooks/use-responsive-font';
import { red, yellow, green } from '@mui/material/colors';
import { DailyInventoryStatus } from '../../../app/interfaces/dailyInventoryStatus';
import { SelectedItem } from '../../../app/interfaces/SelectedItem';

type Props = {
//   data: any;
  fullInventory: any[];
  item: any;
//   fullBundleItems: any[];
  currentQuantity: number | null;
  selectedItems: SelectedItem[];
//   dailyInventoryStatus: DailyInventoryStatus[] | null;
  peakInventory: DailyInventoryStatus | null;
  loadingDailyStatus?: boolean;
};

export default function BundleItemsSummary({
//   data,
  fullInventory,
  item,
//   fullBundleItems,
  currentQuantity,
  selectedItems,
//   dailyInventoryStatus,
  peakInventory,
  loadingDailyStatus,
}: Props) {
    
  const responsiveFontSize = useResponsiveFontSize();

  const BundleItemRow = React.memo(function BundleItemRow({ bundleItem, available }: { bundleItem: any; available: number | null | undefined }) {
    const formatAvailable = (value: number | null | undefined) => {
      if (value === null || value === undefined) return "...";
      return `(${value} left)`;
    };

    return (
      <Stack direction="row" alignItems="center" gap={1} sx={{ ml: 4}}>
        <Chip size="small" label={`${bundleItem.bundleItemQty}x`} sx={{ minWidth: 45 }} />
        <Typography sx={{ maxWidth: "400px", minWidth: "300px" }} fontSize={responsiveFontSize - 1}>{bundleItem.bundleItemName}</Typography>
        <Typography fontSize={responsiveFontSize - 1} color="text.secondary" sx={{ ml: 2 }}>
          {formatAvailable(available)}
        </Typography>
      </Stack>
    );
  });

//   const getAvailabilityForId = (id: string) => {
//     if (!data) return null;

//     const bundleItem = data.bundleItems.find((bi: any) => bi.bundleItemId === id);
//     if (!bundleItem) return null;

//     const foundAvailability = fullInventory.find((a: any) => a.id === id);
//     if (!foundAvailability) return null;

//     const total = foundAvailability.stock - foundAvailability.unavailableStock;
//     return total;
//   };

  const getDailyStatusForId = (id: string) => {
    if (!peakInventory || !peakInventory.items || !peakInventory.items[id]) {
      return null;
    }

    const itemStatus = peakInventory?.items[id];
    if (!itemStatus) return null;

    const foundAvailability = fullInventory.find((a: any) => a.id === id);
    if (!foundAvailability) return null;

    // Calculate quantity from direct items
    const directQuantity = selectedItems
      .filter((i) => i.uuid !== item.uuid && i.id === id)
      .reduce((acc, curr) => acc + (curr.selectedQty || 0), 0);

    // Calculate quantity from bundle items
    const bundleQuantity = selectedItems
      .filter((i) => i.uuid !== item.uuid && i.type === 'bundle' && i.bundleItems)
      .reduce((acc, curr) => {
        const bundleItem = curr.bundleItems.find((bi: any) => bi.bundleItemId === id);
        if (bundleItem) {
          // Multiply bundle item quantity by the bundle's selected quantity
          const qtyPerBundle = bundleItem.bundleItemQty || 0;
          const bundleQty = curr.selectedQty || 0;
          return acc + (qtyPerBundle * bundleQty);
        }
        return acc;
      }, 0);

    // Get current bundle's required quantity for this item
    const currentBundleItem = item.bundleItems.find((bi: any) => bi.bundleItemId === id);
    const currentBundleQuantity = currentBundleItem ? 
      (currentBundleItem.bundleItemQty || 0) * (currentQuantity || 0) : 0;

    const totalQuantityFromOtherItems = directQuantity + bundleQuantity + currentBundleQuantity;
    
    const total = foundAvailability.stock - itemStatus.outByInvoice - totalQuantityFromOtherItems;
    return total;
  };

  // if (!data?.bundleItems) return null;

  return (
    <Stack gap={2}>
      {/* <Stack gap={1}>
        <Typography variant="subtitle2" fontSize={responsiveFontSize} fontWeight="bold" color="text.primary">
          Current Method
        </Typography>
        {data.bundleItems.map((bundleItem: any, index: number) => (
          <BundleItemRow key={`current-${index}`} bundleItem={bundleItem} available={getAvailabilityForId(bundleItem.bundleItemId)} />
        ))}
      </Stack>

      <Divider /> */}

      <Stack gap={1}>
        {/* <Typography variant="subtitle2" fontSize={responsiveFontSize} fontWeight="bold" color="text.primary">
          New Method
        </Typography> */}
        {item.bundleItems.map((bundleItem: any, index: number) => (
          <BundleItemRow key={`new-${index}`} bundleItem={bundleItem} available={getDailyStatusForId(bundleItem.bundleItemId)} />
        ))}
      </Stack>
    </Stack>
  );
} 