import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { DB, functionsBaseUrl } from "../../../app/config/firebase";
import { Customer } from "../../../app/interfaces/customer";
import { justifiConfig } from "../../../app/config/justifi-config";
import { OrganizationData } from "../../../app/interfaces/organizationData";
import { ConfigurePaymentForJustifi } from "../../../app/utils/models/configure/configurePayment";
import { LoadingButton } from "@mui/lab";

type Props = {
  orgData: OrganizationData;
  customer?: Customer;
  total: number;
  credit: number;
  refNo: string;
  fulfilled: any[]
  onClose: () => void;
};

export function JustifiPaymentForm({
  orgData,
  customer,
  total,
  credit,
  refNo,
  fulfilled,
  onClose
}: Props) {

  const theme = useTheme();

  const formRef = useRef<HTMLFormElement>(null);

  const [paymentMethods, setPaymentMethods] = React.useState<any[]>([]);
  const [processingPayment, setProcessingPayment] = useState<boolean>(false);

  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    React.useState<string>("");

  const handleChange = (
    event: SelectChangeEvent<typeof selectedPaymentMethod>
  ) => {
    setSelectedPaymentMethod(event.target.value as string);
  };

  useEffect(() => {
    if (!orgData) return;

    const getPaymentMethods = async () => {

      if (!customer?.justifi.subAccount) {
        return;
      }

      const url = functionsBaseUrl + "/justifi";
      const data = {
        orgId: orgData.id,
        action: "listPaymentMethods",
        payload: {
          forCustomer: customer?.id,
        },
      };

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const json = await response.json();
      setPaymentMethods(json);
    };

    getPaymentMethods();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgData]);

    // MARK: Submit Payment
    const submitPayment = async (event?: Event) => {
      // Two paths: New payment method or existing payment method (which indicates existing customer account)
  
      const url = `${functionsBaseUrl}justifi`;
      const applicationFee = (Math.round((total * 100) * orgData.justifi.ccRate )) + 50; // MARK: TODO ACH
      setProcessingPayment(true);
  
      const paymentPayload = {
        orgId: orgData.id,
        action: "createPayment",
        payload: {
          amount: Math.round(total * 100),
          applicationFeeAmount: applicationFee,
          currency: "usd",
          captureStrategy: "automatic",
          email: customer?.email ?? "",
          description: `Payment for invoice: ${customer?.customerDisplayName}`,
          paymentMethod: {
            token: "placeholder",
          },
          metadata: {
            adelieCustomerId: customer?.id ?? "unknown",
            justifiCustomerId: "placeholder",
            // invoiceId: invoice.id,
            // invoiceNumber: invoice.invoiceNumber,
            orgId: orgData.id,
            companyName: orgData.companyName,
            adeliePaymentId: "placeholder",
          },
        },
      };
  
      if (selectedPaymentMethod) {
        // MARK: Existing Method
        paymentPayload.payload.metadata.justifiCustomerId =
          customer?.justifi.customerAccount ?? "unknown";
        paymentPayload.payload.paymentMethod.token = selectedPaymentMethod;
      } else {
        // There COULD be a new customer as well. Check if there is a justifi.customerAccount on the customer object.
        // If not, update the Customer firestore object with the new customer account and the new payment method token.
        // If there is, just add the new payment method token to the existing customer account.
        // MARK: New Method
  
        if (!event) return;
  
        console.log(
          "Form submitted:",
          JSON.stringify((event as CustomEvent).detail)
        );
  
        const paymentMethodToken = (event as CustomEvent).detail.id;
        const customerAccount = (event as CustomEvent).detail.data.customer_id;
  
        // setNewJustifiCustomerId(customerAccount);
  
        let customerUpdatePayload: any;
  
        if (customer?.justifi?.customerAccount) {
          // Add the new payment method to the customer account
          const updatedPaymentMethodTokens = [
            ...(customer.justifi.paymentMethodTokens ?? []),
            paymentMethodToken,
          ];
          customerUpdatePayload = {
            customerAccount: customer?.justifi?.customerAccount,
            paymentMethodTokens: updatedPaymentMethodTokens,
          };
        } else {
          // Add the new payment method and customer ID to the customer account.
          customerUpdatePayload = {
            customerAccount: customerAccount ?? null,
            paymentMethodTokens: [paymentMethodToken],
          };
        }
  
        customerUpdatePayload.id = customer?.id;
  
        // MARK: Update Customer
        const data = {
          orgId: orgData.id,
          action: "updateCustomerForJustifi",
          payload: customerUpdatePayload,
        };
  
        fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
  
        // Update the payment payload for the new payment method token.
        paymentPayload.payload.paymentMethod.token = paymentMethodToken;
        paymentPayload.payload.metadata.justifiCustomerId =
          customer?.justifi?.customerAccount ?? customerAccount;
      }
  
      // Create the RentKit payment document first.
      // 1. Create the RentKit payment document.
      // 2. Get the document id.
      // 3. Add the document id to the payment metadata.
        
        const paymentDocPayload = ConfigurePaymentForJustifi({
          payload: {
            customer: customer,
            paymentAmt: total,
            creditAmt: credit,
            fulfilled: fulfilled,
            paymentDate: new Date(),
            method: "justifi",
            refNo: refNo ? refNo : "",
          },
        });

        paymentDocPayload.orgId = orgData.id;

        console.log(JSON.stringify(paymentDocPayload))

        const newDocResult = await DB.collection("orgs").doc(orgData.id).collection("payments").add(paymentDocPayload);
        const docId = newDocResult.id;
  
      // // Then create the actual Justifi payment.
      paymentPayload.payload.metadata.adeliePaymentId = docId;
  
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(paymentPayload),
        });
  
        const json = await response.json();
        console.log("Payment response:", json);
        
        onClose();
      } catch (error) {
        console.log("Error creating payment:", error);
        onClose();
      } finally {
        setProcessingPayment(false);
      }
    };

  useEffect(() => {
    if (!orgData) return;

    const form = formRef.current;
    if (form) {

      if (processingPayment) {
        form.disableSubmitButton();
      } else {
        form.enableSubmitButton();
      }

      const handleSubmit = (event: Event) => {
        // Handle the submit event, get the new token or use the selected payment method from token.

        console.log(
          "Form submitted:",
          JSON.stringify((event as CustomEvent).detail)
        );
        submitPayment(event);
      };

      form.addEventListener("submitted", handleSubmit);

      return () => form.removeEventListener("submitted", handleSubmit);
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgData, processingPayment]);

  return (
    <Box sx={{ p: 1 }}>
      <Stack direction={"column"} gap={2}>
        {paymentMethods && paymentMethods.length > 0 && (
          <FormControl sx={{ width: "300px" }}>
            <InputLabel id="payment-method-select-label">
              Payment Method
            </InputLabel>
            <Select
              labelId="payment-method-select-label"
              id="payment-method-select"
              value={selectedPaymentMethod}
              onChange={handleChange}
              label="Payment Method"
            >
              {paymentMethods.map((paymentMethod) => (
                <MenuItem
                  key={paymentMethod.card.id}
                  value={paymentMethod.card.id}
                >
                  {`**** **** **** ${paymentMethod.card.acctLastFour}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {selectedPaymentMethod && orgData.justifiSubAccount && (
            <Stack direction="row" gap={1}>
            <LoadingButton
              loading={processingPayment}
              variant="contained"
              onClick={async () => {
                submitPayment();
              }}
            >
              Pay
            </LoadingButton>

            <Button
              sx={{ background: theme.palette.success.darker }}
              variant="contained"
              disabled={processingPayment}
              onClick={() => {
                setSelectedPaymentMethod("");
              }}
            >
              Add New Payment Method
            </Button>
          </Stack>
        )}

        {!selectedPaymentMethod && orgData.justifiSubAccount && (
          <justifi-payment-form
            style={{
              "--jfi-submit-button-background-color":
                theme.palette.primary.main,
              "--jfi-submit-button-border-color": theme.palette.primary.main,
              "--jfi-submit-button-color": "white",
              "--jfi-submit-button-color-hover": theme.palette.primary.main,
              "--jfi-submit-button-background-color-hover":
                theme.palette.primary.main,
              "--jfi-submit-button-border-color-hover":
                theme.palette.primary.main,
              "--jfi-submit-button-color-focus": theme.palette.primary.main,
              "--jfi-submit-button-background-color-focus":
                theme.palette.primary.main,
              "--jfi-submit-button-border-color-focus":
                theme.palette.primary.main,
              "--jfi-submit-button-color-active": theme.palette.primary.main,

              "--jfi-submit-button-background-color-active":
                theme.palette.primary.main,
              "--jfi-submit-button-border-color-active":
                theme.palette.primary.main,
              "--jfi-sumbit-button-width": theme.palette.primary.main,
              "--jfi-submit-button-box-shadow": theme.palette.primary.main,
              "--jfi-submit-button-color-loading": theme.palette.primary.main,
              "--jfi-submit-button-background-color-loading":
                theme.palette.primary.main,
              "--jfi-submit-button-border-color-loading":
                theme.palette.primary.main,

              "--jfi-radio-button-color": theme.palette.primary.main,
              "--jfi-radio-button-background-color": "white",
              "--jfi-radio-button-color-selected": "white",
              "--jfi-radio-button-background-color-selected":
                theme.palette.primary.main,
              "--jfi-radio-button-border-color": theme.palette.primary.main,
              "--jfi-radio-button-border-color-selected":
                theme.palette.primary.main,
              "--jfi-radio-button-color-hover": "white",
              "--jfi-radio-button-color-selected-hover": "white",
              "--jfi-radio-button-background-color-hover":
                theme.palette.primary.main,
              "--jfi-radio-button-background-color-selected-hover":
                theme.palette.primary.main,
              "--jfi-radio-button-border-color-selected-hover":
                theme.palette.primary.main,
              "--jfi-radio-button-border-color-hover":
                theme.palette.primary.main,
            }}
            ref={formRef}
            client-id={justifiConfig.clientId}
            account-id={orgData.justifiSubAccount}
            email={customer?.email ?? ""}
            bank-account="false"
            card="true"
          />
        )}
      </Stack>
    </Box>
  );
}
