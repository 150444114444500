import React from "react";
import InvMetaDataAlert from "./InvMetaDataAlert";

const InvMetaDataList = ({ invoices, item, orgData }) => {
  // Sort invoices to put estimates at the end
  const sortedInvoices = [...invoices].sort((a, b) => {
    // Ensure we treat undefined/null types as 'invoice'
    const aType = a.type || 'invoice';
    const bType = b.type || 'invoice';
    
    if (aType === 'estimate' && bType !== 'estimate') return 1;
    if (aType !== 'estimate' && bType === 'estimate') return -1;
    
    // If both are the same type, sort by date
    const aDate = a.rentalDateStart?.toDate() || new Date(0);
    const bDate = b.rentalDateStart?.toDate() || new Date(0);
    return aDate.getTime() - bDate.getTime();
  });

  return (
    <div>
      {sortedInvoices &&
        sortedInvoices.map((i) => (
          <InvMetaDataAlert 
            invoice={i} 
            item={item} 
            orgData={orgData} 
            key={i.id} 
            isEstimate={i.type === 'estimate'} 
          />
        ))}
    </div>
  );
};

export default InvMetaDataList;
