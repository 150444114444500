import { useState, useEffect } from 'react'
import { DailyInventoryStatus } from '../../interfaces/dailyInventoryStatus'
import moment from 'moment'
import { DB } from '../../config/firebase'
import { format } from 'date-fns'
import { formatDateOnRead, formatDateOnWrite } from '../../utils/time/formatTimezoneOffset'
import { OrganizationData } from '../../interfaces/organizationData'
import { Invoice } from '../../interfaces/invoice'
import { checkInvoices } from '../../utils/models/checkers/checkInvoices'

interface UseDailyInventoryStatusProps {
  orgData: OrganizationData
  startDate: Date | null
  endDate: Date | null
  invoice?: Invoice // Optional invoice parameter
}

interface UseDailyInventoryStatusResult {
  data: DailyInventoryStatus[] | null
  peakInventory: DailyInventoryStatus | null
  invoices: Invoice[] | null
  isLoading: boolean
  error: Error | null
  refetch: () => Promise<void>
}

export function useDailyInventoryStatus({
  orgData,
  startDate,
  endDate,
  invoice
}: UseDailyInventoryStatusProps): UseDailyInventoryStatusResult {
  const [data, setData] = useState<DailyInventoryStatus[] | null>(null)
  const [peakInventory, setPeakInventory] = useState<DailyInventoryStatus | null>(null)
  const [invoices, setInvoices] = useState<Invoice[] | null>([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  const fetchData = async () => {

    if (!startDate || !endDate || !orgData) {
      setData(null)
      setPeakInventory(null)
      setInvoices(null)
      return
    }

    setIsLoading(true)
    setError(null)

    try {
      const formattedStartDate = formatDateOnRead({
        date: startDate,
        orgTimezone: orgData.orgTimezone
      })

      const formattedEndDate = formatDateOnRead({
        date: endDate,
        orgTimezone: orgData.orgTimezone
      })

      if (!formattedStartDate || !formattedEndDate) {
        throw new Error('Invalid date format')
      }

      const startDateStr = format(new Date(formattedStartDate.seconds * 1000), 'yyyy-MM-dd')
      const endDateStr = format(new Date(formattedEndDate.seconds * 1000), 'yyyy-MM-dd')
      
      // console.log('Querying for date range:', startDateStr, 'to', endDateStr)

      const snapshot = await DB
        .collection('orgs')
        .doc(orgData.id)
        .collection('dailyInventory')
        .where('date', '>=', startDateStr)
        .where('date', '<=', endDateStr)
        .get()

      if (snapshot.empty) {
        setData(null)
        setPeakInventory(null)
        setInvoices(null)
        return
      }

      const dailyStatuses: DailyInventoryStatus[] = []
      // console.log("Count", snapshot.docs.length)
      snapshot.forEach(doc => {
        dailyStatuses.push(doc.data() as DailyInventoryStatus)
      })

      // console.log('Found statuses:', dailyStatuses.map(s => ({ date: s.date })))

      try {
        // Collect unique invoice IDs across all days and items
        const invoiceIds = new Set();
        dailyStatuses.forEach((dayStatus) => {
          Object.values(dayStatus.items).forEach(itemStatus => {
            // console.log('Item status:', itemStatus);
            itemStatus.invoicesQueryHook?.forEach(id => invoiceIds.add(id));
            itemStatus.estimatesQueryHook?.forEach(id => invoiceIds.add(id));
          });
        });

        if (invoiceIds.size === 0) {
          console.log('No invoice IDs found');
          setInvoices([]);
        }

        const uniqueInvoiceIds = Array.from(invoiceIds);
        
        if (uniqueInvoiceIds.length !== 0) {
        
            // Create an array of promises for each invoice fetch
            const fetchPromises = uniqueInvoiceIds.map((id) => 
              DB
              .collection('orgs')
              .doc(orgData.id)
              .collection('invoices')
              .doc(id as string)
              .get()
              .then((doc) => checkInvoices(doc, orgData.orgTimezone))
          );
  
          const results = await Promise.allSettled(fetchPromises);
          
          // Filter out failed fetches and null results
          const invoices = results
            .map(result => result.status === 'fulfilled' ? result.value : null)
            .filter(Boolean);

          // console.log('Fetched invoices from daily status:', invoices.map(inv => inv.id));
  
          setInvoices(invoices);
        } 
        
      } catch (error) {
        console.error('Error fetching invoices:', error);
      }

    
      setData(dailyStatuses)

      // Calculate peak inventory status
      if (dailyStatuses.length > 0) {
        const peakStatus: DailyInventoryStatus = {
          date: format(new Date(), "yyyy-MM-dd"),
          items: {},
          invoicesQueryHook: [],
          estimatesQueryHook: []
        };

        // Get all unique item IDs
        const allItemIds = dailyStatuses.flatMap(status => Object.keys(status.items))
          .filter((value, index, self) => self.indexOf(value) === index);

        // For each item, calculate the peak usage across all days
        allItemIds.forEach(itemId => {
          // Get all daily statuses for this item
          const itemDailyStatuses = dailyStatuses
            .map(day => day.items[itemId])
            .filter(Boolean); // Remove any undefined/null values

          if (itemDailyStatuses.length === 0) return;

          // Get the current invoice's quantity for this item (if any)
          const currentInvoiceQty = getCurrentInvoiceQuantity(invoice, itemId);

          // Find the peak usage day for this item
          const peakUsage = itemDailyStatuses.reduce((peak, current) => {
            // Adjust quantities by removing current invoice's contribution
            const adjustedInvoiceQty = Math.max(0, (current.outByInvoice || 0) - 
              (invoice?.type === 'invoice' ? currentInvoiceQty : 0));
            const adjustedEstimateQty = Math.max(0, (current.outByEstimate || 0) - 
              (invoice?.type === 'estimate' ? currentInvoiceQty : 0));

            return {
              itemName: current.itemName,
              outByInvoice: Math.max(peak.outByInvoice, adjustedInvoiceQty),
              outByEstimate: Math.max(peak.outByEstimate, adjustedEstimateQty),
              // Combine query hooks but exclude current invoice
              invoicesQueryHook: Array.from(new Set([
                ...peak.invoicesQueryHook,
                ...(current.invoicesQueryHook?.filter(id => id !== invoice?.id) || [])
              ])),
              estimatesQueryHook: Array.from(new Set([
                ...peak.estimatesQueryHook,
                ...(current.estimatesQueryHook?.filter(id => id !== invoice?.id) || [])
              ]))
            };
          }, {
            itemName: itemDailyStatuses[0].itemName,
            outByInvoice: 0,
            outByEstimate: 0,
            invoicesQueryHook: [],
            estimatesQueryHook: []
          });

          peakStatus.items[itemId] = peakUsage;
        });

        // Combine all invoice and estimate IDs at the top level
        peakStatus.invoicesQueryHook = Array.from(new Set(
          Object.values(peakStatus.items).flatMap(item => item.invoicesQueryHook)
        ));
        peakStatus.estimatesQueryHook = Array.from(new Set(
          Object.values(peakStatus.items).flatMap(item => item.estimatesQueryHook)
        ));

        setPeakInventory(peakStatus);
      } else {
        setPeakInventory(null);
      }

    } catch (err) {
      setError(err instanceof Error ? err : new Error('Failed to fetch daily inventory status'))
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
      fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    orgData, 
    startDate,
    endDate,
    invoice?.selectedItems
  ])

  return {
    data,
    peakInventory,
    invoices,
    isLoading,
    error,
    refetch: fetchData
  }
}

// Helper function to calculate current invoice quantity
function getCurrentInvoiceQuantity(invoice: Invoice | undefined, itemId: string): number {
  if (!invoice?.selectedItems?.items) return 0;

  return invoice.selectedItems.items.reduce((total, item) => {
    // Direct item quantity
    if (item.id === itemId) {
      return total + (item.selectedQty || 0);
    }
    
    // Bundle item quantity
    if (item.type === 'bundle' && item.bundleItems) {
      const bundleItem = item.bundleItems.find((bi: any) => bi.bundleItemId === itemId);
      if (bundleItem) {
        return total + ((bundleItem.bundleItemQty || 0) * (item.selectedQty || 0));
      }
    }
    return total;
  }, 0);
} 