import { useEffect, useState, useMemo, useCallback } from "react";
import {
  Box,
  Stack,
  Tooltip,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Typography,
  Skeleton,
} from "@mui/material";
import { ImageNotSupported } from '@mui/icons-material';
import {
  COLOR_DARK_BG_0,
  COLOR_RED_0,
  COLOR_TEXT_GRAY_1,
  COLOR_TEXT_GRAY_2,
  COLOR_YELLOW_0,
} from "../../app/ui/colorConstants";
import QuickAdd from "./components/QuickAdd";
import InvMetaDataList from "./components/InvMetaDataList";
import TagTypeWithTooltip from "./components/TagTypeWithTooltip";
import { i18n } from "../../i18n";
import { useInvoicesInRange } from "../../app/services/hooks/fetch/useInvoicesInRange";
import { ItemGanttChart } from "../../components/GanttChart";

// MARK: Custom Progress Bar
const CustomProgressBar = ({ percent, successPercent, format, strokeColor, successStrokeColor }) => {
  const height = 8;
  
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      <Box sx={{ position: 'relative', width: '100%', height }}>
        <LinearProgress
          variant="determinate"
          value={percent}
          sx={{
            height,
            backgroundColor: 'rgba(0,0,0,0.04)',
            borderRadius: 1,
            '& .MuiLinearProgress-bar': {
              backgroundColor: strokeColor,
              transition: 'transform 0.4s linear',
            },
          }}
        />
        {successPercent > 0 && (
          <LinearProgress
            variant="determinate"
            value={successPercent}
            sx={{
              height,
              backgroundColor: 'transparent',
              borderRadius: 1,
              position: 'absolute',
              width: '100%',
              top: 0,
              '& .MuiLinearProgress-bar': {
                backgroundColor: successStrokeColor,
                transition: 'transform 0.4s linear',
              },
            }}
          />
        )}
      </Box>
      <Box sx={{ minWidth: 100 }}>
        {format(percent)}
      </Box>
    </Box>
  );
};

// MARK: LoadingInventoryItem
const LoadingInventoryItem = () => (
  <TableRow>
    <TableCell sx={{ p: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
        <Box sx={{ flexShrink: 0 }}>
          <Skeleton variant="rectangular" width={76} height={76} />
        </Box>
        
        <Box sx={{ flex: 1 }}>
          <Stack spacing={0.5}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Skeleton variant="text" width={200} />
              <Skeleton variant="text" width={60} />
            </Stack>
            
            <Skeleton variant="text" width={300} />
          </Stack>

          <Stack spacing={1} sx={{ width: "100%", mt: 1 }}>
            <Box sx={{ width: "300px" }}>
              <Skeleton variant="rectangular" height={24} />
            </Box>
          </Stack>
        </Box>

        <Box sx={{ flexShrink: 0, width: 100 }}>
          <Skeleton variant="rectangular" height={36} />
        </Box>
      </Box>
    </TableCell>
  </TableRow>
);

// Add Debug Panel Component
function DebugPanel({ item, dailyInventoryStatus, peakUsage, availability }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);

  if (!item) return null;

  const handleCopyDebugData = () => {
    const debugData = {
      itemId: item.id,
      itemName: item.name,
      dailyStatus: dailyInventoryStatus?.map(day => ({
        date: day.date,
        itemStatus: day.items[item.id]
      })),
      peakUsage,
      availability
    };

    navigator.clipboard.writeText(JSON.stringify(debugData, null, 2))
      .then(() => {
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000);
      })
      .catch(err => console.error('Failed to copy:', err));
  };

  return (
    <Box sx={{ mt: 1, border: '1px solid #eee', p: 1, borderRadius: 1 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography 
          variant="body2" 
          sx={{ cursor: 'pointer', userSelect: 'none' }}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? '▼' : '▶'} Debug Info
        </Typography>
        {isExpanded && (
          <Typography
            variant="body2"
            sx={{
              cursor: 'pointer',
              color: copySuccess ? 'success.main' : 'primary.main',
              '&:hover': { textDecoration: 'underline' }
            }}
            onClick={handleCopyDebugData}
          >
            {copySuccess ? 'Copied!' : 'Copy Debug Data'}
          </Typography>
        )}
      </Stack>
      
      {isExpanded && (
        <Box sx={{ mt: 1, whiteSpace: 'pre-wrap', fontFamily: 'monospace', fontSize: '12px' }}>
          <Typography variant="body2" sx={{ fontWeight: 'bold', mb: 0.5 }}>Daily Status Data:</Typography>
          {dailyInventoryStatus?.map(day => ({
            date: day.date,
            itemStatus: day.items[item.id]
          })).map((data, index) => (
            <Box key={index} component="pre" sx={{ m: 0, p: 0.5, backgroundColor: '#f5f5f5' }}>
              {JSON.stringify(data, null, 2)}
            </Box>
          ))}

          <Typography variant="body2" sx={{ fontWeight: 'bold', mt: 1, mb: 0.5 }}>Peak Usage:</Typography>
          <Box component="pre" sx={{ m: 0, p: 0.5, backgroundColor: '#f5f5f5' }}>
            {JSON.stringify(peakUsage, null, 2)}
          </Box>

          <Typography variant="body2" sx={{ fontWeight: 'bold', mt: 1, mb: 0.5 }}>Availability:</Typography>
          <Box component="pre" sx={{ m: 0, p: 0.5, backgroundColor: '#f5f5f5' }}>
            {JSON.stringify(availability, null, 2)}
          </Box>
        </Box>
      )}
    </Box>
  );
}

// MARK: AvailableInventory
const AvailableInventory = ({
  orgData,
  rentalDates,
  inventory,
  allInventory,
  searchStr,
  cart,
  setCart,
  setEmptyState,
  selectedTab,
  dailyStatusInvoices,
  dailyInventoryStatuses,
  peakInventory,
  loadingDailyStatusInvoices,
  loadingDailyStatuses,
  showPriorMethod,
  showDebugPanel,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [failedImages, setFailedImages] = useState(new Set());

  const hasRentalDates = useCallback(() => {
    return rentalDates.rentalDateStart !== null && rentalDates.rentalDateEnd !== null;
  }, [rentalDates]);

  const InvoicesInRange = useInvoicesInRange(
    { useTurnaround: true, ...rentalDates },
    inventory ?? [],
    []
  );

  // Get the processed inventory items with availability data
  const processedInventory = useMemo(() => {
    if (!InvoicesInRange.data || !inventory) return [];
    
    const processed = inventory.map(item => {
      const processedItem = InvoicesInRange.data.find(i => i.id === item.id);
      if (!processedItem) return item;

      // Merge the item with both invoice sources
      const dailyStatusItemInvoices = dailyStatusInvoices?.filter(invoice => {
        return invoice.selectedItems?.items?.some(selectedItem => selectedItem.id === item.id);
      });

      return {
        ...item,
        ...processedItem,
        dailyStatusInvoices: dailyStatusItemInvoices
      };
    });

    // Apply search filter
    if (!searchStr) return processed;
    
    const searchLower = searchStr.toLowerCase();
    return processed.filter(item => 
      item.name?.toLowerCase().includes(searchLower) ||
      item.description?.toLowerCase().includes(searchLower)
    );
  }, [InvoicesInRange.data, inventory, searchStr, dailyStatusInvoices]);

  // MARK: Peak Usage
  // Calculate peak usage across date range
  const getPeakUsage = useCallback((itemId) => {
    if (!dailyInventoryStatuses?.length) {
      // console.log('No dailyInventoryStatus data available');
      return null;
    }

    const peakUsage = dailyInventoryStatuses.reduce((peak, day) => {
      const itemStatus = day.items[itemId];
      
      if (!itemStatus) return peak;
      
      const newPeak = {
        outByInvoice: Math.max(peak.outByInvoice, itemStatus.outByInvoice || 0),
        outByEstimate: Math.max(peak.outByEstimate, itemStatus.outByEstimate || 0),
        invoiceIds: [...(peak.invoiceIds || []), ...(itemStatus.invoicesQueryHook || [])],
        estimateIds: [...(peak.estimateIds || []), ...(itemStatus.estimatesQueryHook || [])]
      };

      // console.log('Day:', day.date, {
      //   dayStatus: itemStatus,
      //   currentPeak: peak,
      //   newPeak
      // });

      return newPeak;
    }, { outByInvoice: 0, outByEstimate: 0, invoiceIds: [], estimateIds: [] });

    console.log('Final peak usage:', peakUsage);
    return peakUsage;
  }, [dailyInventoryStatuses]);

  // MARK: Current Items
  // Get filtered and paginated items
  const currentItems = useMemo(() => {
    const filteredInventory = selectedTab === "booked" && dailyInventoryStatuses?.[0]
      ? processedInventory.filter(item => {
          const peakUsage = getPeakUsage(item.id);
          return peakUsage && (peakUsage.outByInvoice > 0 || peakUsage.outByEstimate > 0);
        })
      : processedInventory;

    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return filteredInventory.slice(startIndex, endIndex);
  }, [processedInventory, page, rowsPerPage, selectedTab, dailyInventoryStatuses, getPeakUsage]);

  // MARK: Get Daily Status Availability
  // Update getDailyStatusAvailability to use peak usage and consider bundle constraints
  const getDailyStatusAvailability = useCallback((item) => {
    if (!peakInventory?.items || !item) {
      console.log('No data for getDailyStatusAvailability:', { peakInventory, item });
      return null;
    }
    
    const itemPeakUsage = peakInventory.items[item.id];
    console.log('Daily status availability for item:', item.name, itemPeakUsage);
    
    if (!itemPeakUsage) return {
      available: item.stock,
      outByInvoice: 0,
      outByEstimate: 0,
      pending: 0,
      invoiceIds: [],
      estimateIds: []
    };

    // Calculate base availability
    const baseAvailability = {
      available: item.stock ? item.stock - itemPeakUsage.outByInvoice : null,
      outByInvoice: itemPeakUsage.outByInvoice,
      outByEstimate: itemPeakUsage.outByEstimate,
      pending: itemPeakUsage.outByEstimate,
      invoiceIds: itemPeakUsage.invoicesQueryHook,
      estimateIds: itemPeakUsage.estimatesQueryHook
    };

    // If this is a bundle, we need to consider the constraints of sub-items
    if (item.type === "bundle" && item.bundleItems) {
      // Calculate availability for each sub-item
      const subItemAvailabilities = item.bundleItems.map(subItem => {
        const subItemInInventory = allInventory.find(i => i.id === subItem.bundleItemId);
        if (!subItemInInventory) {
          // console.log('No sub-item found in processedInventory:', {
          //   subItemId: subItem.bundleItemId,
          //   subItemName: subItem.bundleItemName,
          //   processedInventoryIds: inventory.map(i => i.id)
          // });
          return 0;
        }

        const subItemAvailability = getDailyStatusAvailability(subItemInInventory);
        if (!subItemAvailability) {
          console.log('No availability data for sub-item:', subItem.bundleItemId);
          return 0;
        }

        // Each bundle requires bundleItemQty of this sub-item
        // So divide the sub-item availability by the required quantity
        const available = (subItemAvailability.available || 0) / (subItem.bundleItemQty || 1);
        // console.log('Sub-item availability calculation:', {
        //   subItemId: subItem.bundleItemId,
        //   subItemName: subItem.bundleItemName,
        //   rawAvailable: subItemAvailability.available,
        //   bundleItemQty: subItem.bundleItemQty,
        //   calculatedAvailable: available
        // });
        return Math.floor(available);
      });

      // console.log('Sub-item availabilities:', subItemAvailabilities);

      // Bundle availability is limited by the most constrained sub-item
      const constrainedAvailability = Math.min(baseAvailability.available, ...subItemAvailabilities);

      return {
        ...baseAvailability,
        available: constrainedAvailability
      };
    }

    return baseAvailability;
  }, [peakInventory, processedInventory]);

  // Reset page when filtered inventory changes
  useEffect(() => {
    setPage(0);
  }, [processedInventory.length]);

  useEffect(() => {
    if (!hasRentalDates()) {
      setEmptyState(true);
      return;
    }
    
    setEmptyState(false);
    InvoicesInRange.refresh();
  }, [rentalDates, hasRentalDates]);

  const configurePercent = (i) => {
    const availability = progressBarAvailabilityNumber(i);
    return i.stock ? Math.max(0, (availability / i.stock) * 100) : 0;
  };
  
  const configureSuccessPercent = (i) => {
    const availability = progressBarAvailabilityNumber(i);
    const pendingStock = i.unavailableEstStock || 0;
    return i.stock ? Math.max(0, ((availability - pendingStock) / i.stock) * 100) : 0;
  };

  const handleEmptyState = useCallback(() => {
    return (
      <Typography variant="body1" sx={{ color: COLOR_TEXT_GRAY_1 }}>
        {i18n("availabilityPage.selectDates")}
      </Typography>
    );
  }, []);

  const progressBarTextColor = (i) => {
    const availability = progressBarAvailabilityNumber(i);
    if (availability < 0) {
      return COLOR_RED_0;
    } else if (availability === 0) {
      return COLOR_YELLOW_0;
    } else {
      return COLOR_DARK_BG_0;
    }
  };

  const progressBarAvailabilityNumber = (i) => {
    if (!i.unavailableStock) {
      return i.stock;
    }
    
    if (i.type === "bundle" && i.bundleItems) {
      const bundleAvailability = i.stock - i.unavailableStock;
      const subComponentAvailabilities = i.bundleItems.map(subItem => {
        const subItemInInventory = processedInventory.find(invItem => invItem.id === subItem.bundleItemId);
        if (subItemInInventory) {
          const subItemAvailability = subItemInInventory.stock - subItemInInventory.unavailableStock;
          return Math.floor(subItemAvailability / subItem.bundleItemQty);
        }
        return 0;
      });
      
      let limitingAvailability = Math.min(bundleAvailability, ...subComponentAvailabilities);
      if (!i.autoCalculateStock) {
        limitingAvailability = bundleAvailability;
      }
      return limitingAvailability;
    } else {
      return i.stock - i.unavailableStock;
    }
  };

  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  }, []);

  // MARK: Render
  return (
    <Box sx={{ width: "100%", height: "calc(100vh - 217px)", overflowY: "auto" }}>
      {hasRentalDates() ? (
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Table stickyHeader size="small">
              <TableBody>
                {InvoicesInRange.fetching
                  ? Array(rowsPerPage)
                      .fill(0)
                      .map((_, index) => <LoadingInventoryItem key={index} />)
                  : currentItems.map((i) => (
                      <TableRow key={i.id}>
                        <TableCell sx={{ p: 2 }}>
                          <Box sx={{ display: "flex", alignItems: "flex-start", gap: 2 }}>
                            <Box sx={{ flexShrink: 0 }}>
                              {i.image && !failedImages.has(i.id) ? (
                                <img
                                  src={i.image.downloadURL}
                                  style={{
                                    height: "76px",
                                    width: "76px",
                                    objectFit: "contain",
                                  }}
                                  alt={i.image.fileName}
                                  onError={() => {
                                    setFailedImages((prev) => new Set([...prev, i.id]));
                                  }}
                                />
                              ) : (
                                <ImageNotSupported
                                  sx={{
                                    color: COLOR_TEXT_GRAY_2,
                                    fontSize: 76,
                                  }}
                                />
                              )}
                            </Box>

                            <Box sx={{ flex: 1 }}>
                              <Stack spacing={0.5}>
                                <Stack direction="row" spacing={1} alignItems="center">
                                  <Typography variant="body1" sx={{ fontSize: 16, fontWeight: "bold" }}>
                                    {i.name}
                                  </Typography>
                                  <TagTypeWithTooltip
                                    item={i}
                                    availableInventory={InvoicesInRange.data}
                                    getDailyStatusAvailability={getDailyStatusAvailability}
                                    loadingDailyStatus={loadingDailyStatuses}
                                    peakInventory={peakInventory}
                                  />
                                </Stack>

                                {i.description && (
                                  <Typography variant="body2" sx={{ fontSize: 14, color: "text.secondary" }}>
                                    {i.description}
                                  </Typography>
                                )}
                              </Stack>

                              <Stack spacing={1} sx={{ width: "100%", mt: 1 }}>

                                 {/* New Daily Status Method */}
                                 <Box sx={{ width: "300px" }}>
                                  {i.stock ? (
                                    <Tooltip
                                      title={
                                        loadingDailyStatuses
                                          ? "Loading..."
                                          : (() => {
                                              const availability = getDailyStatusAvailability(i);
                                              if (!availability) return "No data available";
                                              return `New Method\nAvailable: ${availability.available}\nOut: ${availability.outByInvoice}\nPending: ${availability.pending}\nTotal: ${i.stock}`;
                                            })()
                                      }
                                      arrow
                                      componentsProps={{
                                        tooltip: {
                                          sx: {
                                            fontSize: "14px",
                                            padding: "8px 12px",
                                            whiteSpace: "pre-line",
                                          },
                                        },
                                      }}
                                    >
                                      <Box>
                                        <CustomProgressBar
                                          strokeColor="#BAE7CB"
                                          format={(p) => {
                                            const availability = getDailyStatusAvailability(i);
                                            if (loadingDailyStatuses) return "Loading...";
                                            if (!availability) return "No data";
                                            return (
                                              <Typography
                                                variant="body2"
                                                fontSize={12}
                                                sx={{ color: availability.available < 0 ? COLOR_RED_0 : COLOR_DARK_BG_0 }}
                                              >
                                                {`${Math.abs(availability.available)} ${availability.available < 0 ? "Overbooked" : "Left"}`}
                                              </Typography>
                                            );
                                          }}
                                          percent={(() => {
                                            const availability = getDailyStatusAvailability(i);
                                            if (!availability || !i.stock) return 0;
                                            return Math.max(0, (availability.available / i.stock) * 100);
                                          })()}
                                          successPercent={(() => {
                                            const availability = getDailyStatusAvailability(i);
                                            if (!availability || !i.stock) return 0;
                                            return Math.max(0, ((availability.available - availability.pending) / i.stock) * 100);
                                          })()}
                                          successStrokeColor="#38BB69"
                                        />
                                      </Box>
                                    </Tooltip>
                                  ) : (
                                    <Typography variant="body2">No stock info available</Typography>
                                  )}
                                </Box>

                                {/* Current Method */}
                                {showPriorMethod && (
                                  <Box sx={{ width: "300px" }}>
                                    {i.stock ? (
                                      <Tooltip
                                        title={`Current Method\nAvailable: ${progressBarAvailabilityNumber(i)}\nPending: ${
                                          i.unavailableEstStock || 0
                                        }\nTotal: ${i.stock ? i.stock : "NA"}`}
                                        arrow
                                        componentsProps={{
                                          tooltip: {
                                            sx: {
                                              fontSize: "14px",
                                              padding: "8px 12px",
                                              whiteSpace: "pre-line",
                                            },
                                          },
                                        }}
                                      >
                                        <Box>
                                          <CustomProgressBar
                                            strokeColor="#FFE0B2"
                                            format={(p) => (
                                              <Typography variant="body2" fontSize={12} sx={{ color: progressBarTextColor(i) }}>
                                                {`${Math.abs(progressBarAvailabilityNumber(i))} ${
                                                  progressBarAvailabilityNumber(i) < 0 ? "Overbooked" : "Left"
                                                }`}
                                              </Typography>
                                            )}
                                            percent={configurePercent(i)}
                                            successPercent={configureSuccessPercent(i)}
                                            successStrokeColor="#FFB74D"
                                          />
                                        </Box>
                                      </Tooltip>
                                    ) : (
                                      <Typography variant="body2">No stock info available</Typography>
                                    )}
                                  </Box>
                                )}

                               
                                {showDebugPanel && (
                                  <DebugPanel
                                    item={i}
                                    dailyInventoryStatus={dailyInventoryStatuses}
                                    peakUsage={getPeakUsage(i.id)}
                                    availability={getDailyStatusAvailability(i)}
                                  />
                                )}
                              </Stack>
                            </Box>

                            <Box sx={{ flexShrink: 0 }}>
                              <QuickAdd itemMax={i.availableStock} setCart={setCart} cart={cart} itemId={i.id} item={i} />
                            </Box>
                          </Box>

                             {/* MARK: New Method */}
                             {dailyStatusInvoices &&
                            dailyStatusInvoices.length > 0 &&
                            dailyStatusInvoices.some((inv) => inv.selectedItemsQueryHook?.includes(i.id) || inv.bundleItemsQueryHook?.includes(i.id)) && (
                              <Box sx={{ mt: 1 }}>
                                {showPriorMethod && (
                                  <Typography variant="body1" sx={{ fontSize: 16, fontWeight: "bold" }}>
                                    New Method
                                  </Typography>
                                )}
                                <InvMetaDataList invoices={dailyStatusInvoices} item={i} orgData={orgData} />

                                <Box sx={{ border: "1px solid #eee", p: 1, mt: 1, borderRadius: 1 }}>
                                  <ItemGanttChart
                                    rentals={dailyStatusInvoices
                                      .filter((inv) => {
                                        return inv.selectedItemsQueryHook?.includes(i.id) || inv.bundleItemsQueryHook?.includes(i.id);
                                      })
                                      .sort((a, b) => {
                                        return a.rentalDateStart.seconds - b.rentalDateStart.seconds;
                                      })
                                      .map((invoice) => {
                                        // Calculate quantity from selectedItems only
                                        const qtyFromSelectedItems =
                                          invoice.selectedItems?.items
                                            ?.filter((item) => item.id === i.id)
                                            ?.reduce((total, item) => total + (item.selectedQty || 0), 0) || 0;

                                        let qtyFromBundleItems = 0;
                                        if (invoice.bundleItemsQueryHook && invoice.bundleItemsQueryHook.length > 0) {
                                          invoice.selectedItems.items.forEach((si) => {
                                            if (si.type === "bundle") {
                                              si.bundleItems.forEach((bundleItem) => {
                                                if (bundleItem.bundleItemId === i.id) {
                                                  qtyFromBundleItems += (bundleItem.bundleItemQty || 0) * si.selectedQty;
                                                }
                                              });
                                            }
                                          });
                                        }

                                        const quantity = qtyFromSelectedItems + qtyFromBundleItems;

                                        return {
                                          id: invoice.id,
                                          leavingDate: invoice.rentalDateStart?.toDate(),
                                          returningDate: invoice.rentalDateEnd?.toDate(),
                                          quantity,
                                          customerName: invoice.customer?.customerDisplayName || "Unknown Customer",
                                          type: invoice.type,
                                          invoiceNumber: invoice.invoiceNumber || invoice.id.slice(-8),
                                        };
                                      })}
                                    startDate={rentalDates.rentalDateStart}
                                    endDate={rentalDates.rentalDateEnd}
                                  />
                                </Box>
                              </Box>
                            )}

                          {/* MARK: GANTT CHART 
                              Old Method */}
                          {showPriorMethod && i.invoices && i.invoices.length > 0 && (
                            <Box sx={{ mt: 1 }}>
                              <Typography variant="body1" sx={{ fontSize: 16, fontWeight: "bold" }}>
                                Old Method
                              </Typography>
                              <InvMetaDataList invoices={i.invoices} item={i} orgData={orgData} />

                              <Box sx={{ border: "1px solid #eee", p: 1, mt: 1, borderRadius: 1 }}>
                                <ItemGanttChart
                                  rentals={i.invoices
                                    .sort((a, b) => {
                                      return a.rentalDateStart.seconds - b.rentalDateStart.seconds;
                                    })
                                    .map((invoice) => {
                                      // Calculate quantity from selectedItems only
                                      const qtyFromSelectedItems =
                                        invoice.selectedItems?.items
                                          ?.filter((item) => item.id === i.id)
                                          ?.reduce((total, item) => total + (item.selectedQty || 0), 0) || 0;

                                      let qtyFromBundleItems = 0;
                                      if (invoice.bundleItemsQueryHook && invoice.bundleItemsQueryHook.length > 0) {
                                        invoice.selectedItems.items.forEach((si) => {
                                          if (si.type === "bundle") {
                                            si.bundleItems.forEach((bundleItem) => {
                                              if (bundleItem.bundleItemId === i.id) {
                                                qtyFromBundleItems += (bundleItem.bundleItemQty || 0) * si.selectedQty;
                                              }
                                            });
                                          }
                                        });
                                      }

                                      const quantity = qtyFromSelectedItems + qtyFromBundleItems;

                                      return {
                                        id: invoice.id,
                                        leavingDate: invoice.rentalDateStart?.toDate(),
                                        returningDate: invoice.rentalDateEnd?.toDate(),
                                        quantity,
                                        customerName: invoice.customer?.customerDisplayName || "Unknown Customer",
                                        type: invoice.type,
                                        invoiceNumber: invoice.invoiceNumber || invoice.id.slice(-4),
                                      };
                                    })}
                                  startDate={rentalDates.rentalDateStart}
                                  endDate={rentalDates.rentalDateEnd}
                                />
                              </Box>
                            </Box>
                          )}

                       
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={processedInventory.length}
            rowsPerPage={rowsPerPage}
            page={Math.min(page, Math.ceil(processedInventory.length / rowsPerPage) - 1)}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      ) : (
        handleEmptyState()
      )}
    </Box>
  );
};

export default AvailableInventory;

export const useAvailableInventoryDbWrapper = () => {};
