import React from 'react';
import { Alert, Tooltip, Row, Col } from 'antd';
import { format, fromUnixTime } from 'date-fns';
import { Link } from 'react-router-dom';
import { ROUTE_EDIT_INVOICE } from '../../../app/routes';
import { formatDateOnRead } from '../../../app/utils/time/formatTimezoneOffset';
import { Invoice } from '../../../app/interfaces/invoice';
import firebase from 'firebase/app';
import { OrganizationData } from '../../../app/interfaces/organizationData';
import { Box, Typography } from '@mui/material';

interface InvMetaDataAlertProps {
  invoice: Invoice | null;
  item: any;
  orgData: OrganizationData;
  isEstimate?: boolean;
}

interface MetaData {
  qty: number;
  eventDate: string | null;
  rentalDateStart: string;
  rentalDateStartTime: string;
  rentalDateEnd: string;
  rentalDateEndTime: string;
}

// Type guard to check if timestamp is valid Firebase timestamp
const isValidTimestamp = (timestamp: any): timestamp is firebase.firestore.Timestamp => {
  return timestamp && typeof timestamp.seconds === 'number';
};

const InvMetaDataAlert: React.FC<InvMetaDataAlertProps> = ({ invoice, item, orgData, isEstimate }) => {

  const itemId = item.id;

  if (!invoice || !itemId || !invoice.qrItems) {
    return null;
  }

  // Calculate quantity from matching items - check selectedItems
  const qtyFromSelectedItems = invoice.selectedItems?.items
    ?.filter(item => item.id === itemId)
    .reduce((total, item) => total + (item.selectedQty || 0), 0) || 0;

  let qtyFromBundleItems = 0;
  if (invoice.bundleItemsQueryHook && invoice.bundleItemsQueryHook.length > 0) {
    invoice.selectedItems.items.forEach(item => {
      if (item.type === "bundle") {
        item.bundleItems.forEach(bundleItem => {
          if (bundleItem.bundleItemId === itemId) {
            qtyFromBundleItems += (bundleItem.bundleItemQty || 0) * (item.selectedQty || 0);
          }
        });
      }
    });
  }

  const qty = qtyFromSelectedItems + qtyFromBundleItems;

  if (qty === 0) {
    console.log("NOT QUANTTY", invoice);
    return null;
  }

  // Format dates with proper type checking
  const formatFirebaseDate = (timestamp: firebase.firestore.Timestamp | null | undefined, formatStr: string): string => {
    if (!isValidTimestamp(timestamp)) return '';
    return format(fromUnixTime(timestamp.seconds), formatStr);
  };

  const formattedData: MetaData = {
    qty,
    eventDate: invoice.eventDate
      ? formatFirebaseDate(invoice.eventDate, 'MMM do yyyy')
      : null,
    rentalDateStart: formatFirebaseDate(invoice.rentalDateStart, 'MMMM do yyyy') || '',
    rentalDateStartTime: formatFirebaseDate(invoice.specifiedReceiveTime, 'h:mm a') || '', 
    rentalDateEnd: formatFirebaseDate(invoice.rentalDateEnd, 'MMMM do yyyy') || '',
    rentalDateEndTime: formatFirebaseDate(invoice.specifiedReturnTimeEnd, 'h:mm a') || '', 
  };

  return (
    <Box
      sx={{
        padding: '0 8px',
        backgroundColor: isEstimate ? 'var(--color-light-yellow, #fff7e6)' : 'var(--color-light-blue, #e6f7ff)',
        borderRadius: '4px',
        marginTop: '8px',
      }}
    >
      <Row>
        <Col span={6}>
        {
          isEstimate ? (
            <Typography variant='body2' component="span" sx={{ fontWeight: 'bold' }}>
              {formattedData.qty.toLocaleString()}
            </Typography>
          ) : (
            <Typography variant='body2' component="span" sx={{ fontWeight: 'bold' }}>
              {formattedData.qty.toLocaleString()}
            </Typography>
          )
        }
          {isEstimate ? ' quoted on ' : ' assigned to '}
          <Tooltip title="Open in new tab" placement="right">
            <Link
              to={`${ROUTE_EDIT_INVOICE}${invoice.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {invoice.customer?.customerDisplayName}{' '}
              (#{invoice.invoiceNumber})
            </Link>
          </Tooltip>
        </Col>
        <Col span={6}>
          <Typography variant='body2' component="span" sx={{ fontWeight: 'bold' }}>
            Event Date:{' '}
          </Typography>
          {formattedData.eventDate || '--'}
        </Col>
        <Col span={6}>
          <Typography variant='body2' component="span" sx={{ fontWeight: 'bold' }}>
            Leaving:{' '}
          </Typography>
          {`${formattedData.rentalDateStart}${invoice.specifiedReceiveTime ? ` at ${formattedData.rentalDateStartTime}` : ''}`}
        </Col>
        <Col span={6}>
          <Typography variant='body2' component="span" sx={{ fontWeight: 'bold' }}>
            Returning:{' '}
          </Typography>
          {`${formattedData.rentalDateEnd}${invoice.specifiedReturnTimeEnd ? ` at ${formattedData.rentalDateEndTime}` : ''} ${item.turnaround ? `+ ${item.turnaround} days` : ''}`}
        </Col>
      </Row>
    </Box>
  );
};

export default InvMetaDataAlert;
