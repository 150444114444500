import firebase from 'firebase/app';
// ----------------------------------------------------------------------

export type IFileFilterValue = string | string[] | Date | null;

export type IFileFilters = {
  name: string;
  type: string[];
  startDate: Date | null;
  endDate: Date | null;
};

// ----------------------------------------------------------------------

export type IFileShared = {
  id: string;
  name: string;
  email: string;
  avatarUrl: string;
  permission: string;
};

export type IFolderManager = {
  id: string;
  name: string;
  size: number;
  type: string;
  url: string;
  tags: string[];
  totalFiles?: number;
  isFavorited: boolean;
  shared: IFileShared[] | null;
  createdAt: Date | number | string;
  modifiedAt: Date | number | string;
};

export type IFileManager = {
  id: string;
  name: string;
  size: number;
  type: string | "image" | "audio" | "video" | "document" | "other";
  url: string;
  tags: string[];
  isFavorited: boolean;
  shared: IFileShared[] | null;
  createdAt: Date | number | string;
  modifiedAt: Date | number | string;
  onSiteGlobalAttachment: boolean;
  willCallGlobalAttachment: boolean;
};

export type IFile = IFileManager | IFolderManager;

export const CheckFile = (snap: firebase.firestore.DocumentSnapshot) => {
  const data = snap.data() ?? {};
  data.createdAt = (data.createdAt as firebase.firestore.Timestamp).toDate();
  data.modifiedAt = (data.modifiedAt as firebase.firestore.Timestamp).toDate();

  return data as IFile;
}