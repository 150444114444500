// React imports
import { useEffect, useState, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { useFirestore } from 'react-redux-firebase';

// Ant Design imports
import {
  Affix,
  PageHeader,
  Row,
  Col,
  Spin,
  Card,
  Space,
  Divider,
  Input,
} from "antd";

// Local component imports
import AvailableInventory from "./AvailableInventory";

// Utility and configuration imports
import { Spinner } from "../../app/ui/spinner";
import { usePrevious } from "../../app/services/hooks/usePrevious";
import { authSelector } from "../auth/authSlice";
import { FormControlLabel, Stack, Switch, Tab, Tabs } from "@mui/material";
import { useFirestoreContext } from "../../app/global-context/FirestoreContext";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";

// Other imports
import { startOfDay, endOfDay, startOfWeek, endOfWeek, startOfMonth, endOfMonth, addDays, addWeeks, addMonths } from 'date-fns';
import { i18n } from "../../i18n";
import "../../App.less";
import { formatDateOnWrite } from "../../app/utils/time/formatTimezoneOffset";
import { DB } from "../../app/config/firebase";
import { useDailyInventoryStatus } from "../../app/services/hooks/useDailyInventoryStatus";
import { Box, Typography } from "@mui/material";

const { Search } = Input;

const Inventory = ({ cart, setCart, rentalDates, setRentalDates }) => {
  const { orgData } = useSelector(authSelector);
  const { inventory, loadingInventory } = useFirestoreContext();
  const firestore = useFirestore();
  const [search, setSearch] = useState("");
  const [emptyState, setEmptyState] = useState(true);
  const prevSearch = usePrevious(search);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [currentDates, setCurrentDates] = useState([null, null]);
  // const [dailyStatusInvoices, setDailyStatusInvoices] = useState([]);
  const [loadingDailyStatusInvoices, setLoadingDailyStatusInvoices] = useState(false);

  const [showPriorMethod, setShowPriorMethod] = useState(false);
  const [showDebugPanel, setShowDebugPanel] = useState(false);

  // Fetch categories from Firestore
  const [categories, setCategories] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(true);

  const [selectedTab, setSelectedTab] = useState('all'); // 'all' | 'booked' | 'overbooked'

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // MARK: Categories
  const fetchCategories = useCallback(async () => {
    if (!orgData?.id) {
      console.log('No orgId found, returning early');
      return;
    }

    try {
      const categoriesRef = DB
        .collection("orgs")
        .doc(orgData.id)
        .collection("categories");
      
      const snapshot = await categoriesRef.get();

      if (snapshot.empty) {
        setCategories([]);
        setLoadingCategories(false);
        return;
      }

  
      const cats = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      cats.sort((a, b) => a.name.localeCompare(b.name))
      setCategories(cats);
      setLoadingCategories(false);
    } catch (error) {
      console.error('Error fetching categories:', error);
      setLoadingCategories(false);
    }
  }, [orgData]);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories, orgData]);

  useEffect(() => {
    if (rentalDates.rentalDateStart && rentalDates.rentalDateEnd) {
      setEmptyState(false);
    }
  }, []);

  const handleRange = (newDates) => {
    if (newDates[0] && newDates[1]) {
      // Store dates in session storage
      const dateRangeToStore = {
        startDate: newDates[0].toISOString(),
        endDate: newDates[1].toISOString(),
      };
      sessionStorage.setItem("selectedDateRangeAvailableInventory", JSON.stringify(dateRangeToStore));
      setCurrentDates(newDates);

      setRentalDates({
        rentalDateStart: formatDateOnWrite({
          date: startOfDay(newDates[0]),
          orgTimezone: orgData.orgTimezone
        }),
        rentalDateEnd: formatDateOnWrite({
          date: endOfDay(newDates[1]),
          orgTimezone: orgData.orgTimezone
        }),
      });
      setEmptyState(false);
    } else {
      sessionStorage.removeItem("selectedDateRangeAvailableInventory");
      setCurrentDates([null, null]);
      setRentalDates({
        rentalDateStart: null,
        rentalDateEnd: null,
      });
      setEmptyState(true);
    }
  };

  // MARK: Filter Inventory
  // Filter inventory based on search, category, and tab
  const filteredInventory = useMemo(() => {

    if (search === '' && selectedCategory === null) {
      return inventory;
    }

    if (!inventory) return [];
    
    return inventory.filter((item) => {
      // Search filter
      const searchLower = search.toLowerCase();
      const matchesSearch = !search || item.name?.toLowerCase().includes(searchLower) || item.description?.toLowerCase().includes(searchLower);

      // Category filter
      const matchesCategory = !selectedCategory || item.categories?.some((cat) => cat.name === selectedCategory);

      return matchesSearch && matchesCategory;
    });
  }, [inventory, search, selectedCategory]);

  const { data: dailyInventoryStatus, 
    isLoading: loadingDailyStatus, 
    invoices: dailyStatusInvoices,
    peakInventory,
  } = useDailyInventoryStatus({
    orgData,
    startDate: rentalDates.rentalDateStart,
    endDate: rentalDates.rentalDateEnd,
  });

  const bookedInventory = useMemo(() => {    
    if (loadingDailyStatus || !dailyInventoryStatus) return [];

    const statusItems = dailyInventoryStatus?.[0]?.items;
    
    if (!statusItems || !inventory) return [];

    // Find the items in the inventory that have actual bookings
    const bookedItems = inventory.filter(item => {
      const itemStatus = statusItems[item?.id];
      return itemStatus && (itemStatus.outByInvoice > 0 || itemStatus.outByEstimate > 0);
    });

    if (search === '' && selectedCategory === null) {
      return bookedItems;
    }
    
    return bookedItems.filter(item => {
      // Search filter
      const searchLower = search.toLowerCase();
      const matchesSearch = !search || 
        item.name?.toLowerCase().includes(searchLower) ||
        item.description?.toLowerCase().includes(searchLower);

      // Category filter
      const matchesCategory = !selectedCategory ||
        item.categories?.some(cat => cat.name === selectedCategory);

      return matchesSearch && matchesCategory;
    });

  }, [dailyInventoryStatus, inventory, loadingDailyStatus, search, selectedCategory]);

  const overbookedInventory = useMemo(() => {    
    if (!peakInventory) return [];

    const statusItems = peakInventory?.items;
    
    if (!statusItems || !inventory) return [];

    // Find items that are overbooked (outByInvoice exceeds stock)
    const overbookedItems = inventory.filter(item => {
      const statusItem = statusItems[item?.id];
      return (item?.stock !== undefined && item?.stock > 0) && statusItem &&  statusItem.outByInvoice > item.stock;
    });

    if (search === '' && selectedCategory === null) {
      return overbookedItems;
    }
    
    return overbookedItems.filter(item => {
      // Search filter
      const searchLower = search.toLowerCase();
      const matchesSearch = !search || 
        item.name?.toLowerCase().includes(searchLower) ||
        item.description?.toLowerCase().includes(searchLower);

      // Category filter
      const matchesCategory = !selectedCategory ||
        item.categories?.some(cat => cat.name === selectedCategory);

      return matchesSearch && matchesCategory;
    });

  }, [peakInventory, inventory, search, selectedCategory]);

  // Set initial values.
  useEffect(() => {
    const storedDateRange = sessionStorage.getItem('selectedDateRangeAvailableInventory');

    // Check if a date range was previously stored
    if (storedDateRange) {
      const parsedDateRange = JSON.parse(storedDateRange);
      const startDate = new Date(parsedDateRange.startDate);
      const endDate = new Date(parsedDateRange.endDate);
      
      setCurrentDates([startDate, endDate]);
      handleRange([startDate, endDate]);
    } else {
      const today = new Date();
      setCurrentDates([today, today]);
      handleRange([today, today]);
    }
  }, []); // Add handleRange to deps if needed

  return (
    <div>
      

      <Spin indicator={Spinner} style={{ marginTop: "120px" }} spinning={!orgData || loadingInventory || loadingDailyStatus}>
        <Affix>
          <PageHeader className="PageHeader" backIcon={false} title={i18n("availabilityPage.title")} />
        </Affix>

       
        <div style={{ padding: "76px 20px 20px" }}>
        <Box 
        sx={{ 
          backgroundColor: '#FFF3C4', // Light butter yellow
          p: 2,
          mb: 2,
          borderRadius: '6px',
          textAlign: 'center',
          borderBottom: '1px solid #FFE58F'
        }}
      >
        <Typography variant="body1">
          🎉 Updates to the Check Availability Page. More accurate. View estimates, turnaround time, and timeline. Toggle between current and new methods. Overbooked and booked tabs.
        </Typography>
      </Box>

          <Space className="available-inventory-date-picker">
            <div style={{ textAlign: "left", width: "100%" }}>
              <DateRangePicker
                size="small"
                value={currentDates}
                onAccept={(newValue) => handleRange(newValue)}
                slotProps={{
                  textField: {
                    size: "small",
                    sx: {
                      width: "300px",
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: "white",
                        borderRadius: "6px",
                        "& fieldset": {
                          borderRadius: "6px",
                        },
                      },
                    },
                  },
                  shortcuts: {
                    items: [
                      {
                        label: "Today",
                        getValue: () => {
                          const today = new Date();
                          return [startOfDay(today), endOfDay(today)];
                        },
                      },
                      {
                        label: "Tomorrow",
                        getValue: () => {
                          const tomorrow = addDays(new Date(), 1);
                          return [startOfDay(tomorrow), endOfDay(tomorrow)];
                        },
                      },
                      {
                        label: "This Week",
                        getValue: () => {
                          const today = new Date();
                          return [startOfWeek(today), endOfWeek(today)];
                        },
                      },
                      {
                        label: "Next Week",
                        getValue: () => {
                          const nextWeek = addWeeks(new Date(), 1);
                          return [startOfWeek(nextWeek), endOfWeek(nextWeek)];
                        },
                      },
                      {
                        label: "This Month",
                        getValue: () => {
                          const today = new Date();
                          return [startOfMonth(today), endOfMonth(today)];
                        },
                      },
                      {
                        label: "Next Month",
                        getValue: () => {
                          const nextMonth = addMonths(new Date(), 1);
                          return [startOfMonth(nextMonth), endOfMonth(nextMonth)];
                        },
                      },
                      { label: "Reset", getValue: () => [null, null] },
                    ],
                  },
                  actionBar: {
                    actions: ["today"],
                  },
                }}
              />
            </div>
            <div
              style={{
                width: "100%",
                textAlign: "center",
              }}
            >
              <Search placeholder="Search inventory..." allowClear onChange={(e) => setSearch(e.target.value)} style={{ width: 300 }} />
            </div>
          </Space>

          <div style={{ marginTop: "12px", marginBottom: "8px" }}></div>

          {!emptyState && (
            <Stack direction="row" spacing={2} sx={{ marginTop: "12px", marginBottom: "8px", justifyContent: "space-between" }}>
              <Tabs size="small" value={selectedTab} onChange={handleTabChange}>
                <Tab label="All" value="all" />
                <Tab label="Overbooked" value="overbooked" />
                <Tab label="Booked" value="booked" />
              </Tabs>

              <Stack direction="row" spacing={2} sx={{ paddingRight: "18px" }}>
                <FormControlLabel
                  control={<Switch checked={showPriorMethod} onChange={() => setShowPriorMethod(!showPriorMethod)} name="showPriorMethod" color="primary" />}
                  label="Show Prior Method"
                />

                <FormControlLabel
                  control={<Switch checked={showDebugPanel} onChange={() => setShowDebugPanel(!showDebugPanel)} name="showDebugPanel" color="primary" />}
                  label="Show Debug Panel"
                />
              </Stack>
            </Stack>
          )}

          <Row justify={"space-between"} wrap={false} className="available-inventory-table">
            <Col span={18}>
              <AvailableInventory
                orgData={orgData}
                rentalDates={rentalDates}
                inventory={selectedTab === "booked" ? bookedInventory : selectedTab === "overbooked" ? overbookedInventory : filteredInventory}
                allInventory={inventory}
                searchStr={search}
                setCart={setCart}
                cart={cart}
                prevSearch={prevSearch}
                setEmptyState={setEmptyState}
                selectedTab={selectedTab}
                dailyStatusInvoices={dailyStatusInvoices}
                loadingDailyStatusInvoices={loadingDailyStatusInvoices}
                dailyInventoryStatuses={dailyInventoryStatus}
                loadingDailyStatuses={loadingDailyStatus}
                peakInventory={peakInventory}
                showPriorMethod={showPriorMethod}
                showDebugPanel={showDebugPanel}
              />
            </Col>

            {!emptyState ? (
              <Col span={6} style={{ padding: "0 24px" }}>
                <Card
                  style={{
                    height: "calc(100vh - 217px)",
                    overflowY: "auto",
                  }}
                  title={i18n("inventory.filters")}
                  size={"small"}
                >
                  {categories.map((category) => (
                    <div
                      key={category.id}
                      onClick={() => setSelectedCategory(selectedCategory === category.name ? null : category.name)}
                      style={{
                        padding: "8px",
                        cursor: "pointer",
                        backgroundColor: selectedCategory === category.name ? "#e6f7ff" : "transparent",
                        borderRadius: "4px",
                        marginBottom: "4px",
                      }}
                    >
                      {category.name}
                    </div>
                  ))}
                </Card>
              </Col>
            ) : null}
          </Row>
        </div>
      </Spin>
    </div>
  );
};

export default Inventory;
