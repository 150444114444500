import React from 'react'
import { Box, Typography } from '@mui/material'
import moment from 'moment'

export const ItemGanttChart = ({ rentals, startDate, endDate }) => {
  console.log('ItemGanttChart Props:', { rentals, startDate, endDate })

  if (!rentals?.length || !startDate || !endDate) {
    console.log('Missing required data')
    return null
  }

  // Find the earliest and latest dates from rentals
  const earliestRental = moment.min(rentals.map(r => moment(r.leavingDate).startOf('day')))
  const latestRental = moment.max(rentals.map(r => moment(r.returningDate).endOf('day')))
  
  // Use the wider range between provided dates and rental dates
  const start = moment.min(moment(startDate).startOf('day'), earliestRental)
  const end = moment.max(moment(endDate).endOf('day'), latestRental)
  
  // Calculate total days in range for scaling
  const totalDays = end.diff(start, 'days')
  // console.log('Timeline:', { start: start.format(), end: end.format(), totalDays })
  
  // Generate date labels for the timeline
  const dateLabels = Array.from({ length: totalDays + 1 }, (_, i) => {
    const date = start.clone().add(i, 'days')
    return {
      text: date.format('M/D'),
      date: date
    }
  })

  // Assign tracks to rentals based on overlaps
  const assignTracks = (rentals) => {
    const tracks = []
    
    rentals.forEach(rental => {
      const rentalStart = moment(rental.leavingDate).startOf('day')
      const rentalEnd = moment(rental.returningDate).endOf('day')
      
      // Find first available track
      let trackIndex = 0
      while (true) {
        const track = tracks[trackIndex] || []
        const hasOverlap = track.some(existingRental => {
          const existingStart = moment(existingRental.leavingDate).startOf('day')
          const existingEnd = moment(existingRental.returningDate).endOf('day')
          return !(rentalEnd.isSameOrBefore(existingStart) || rentalStart.isSameOrAfter(existingEnd))
        })
        
        if (!hasOverlap) {
          if (!tracks[trackIndex]) tracks[trackIndex] = []
          tracks[trackIndex].push(rental)
          rental.track = trackIndex
          break
        }
        trackIndex++
      }
    })
    
    return tracks.length
  }

  const numTracks = assignTracks(rentals)
  const trackHeight = 24 // height of each rental bar
  const totalHeight = 24 + (trackHeight * numTracks) // header height + (track height × number of tracks)

  return (
    <Box sx={{ 
      width: '100%', 
      height: totalHeight,
      position: 'relative',
      mt: 1,
      backgroundColor: '#f5f5f5'
    }}>
      {/* Date labels and grid lines container */}
      <Box sx={{ 
        display: 'flex',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}>
        {dateLabels.map((dateInfo, index) => (
          <Box 
            key={dateInfo.text}
            sx={{
              flex: 1,
              position: 'relative',
              borderRight: index < dateLabels.length - 1 ? '1px solid #ddd' : 'none',
            }}
          >
            {/* Date label */}
            <Typography 
              variant="caption" 
              sx={{ 
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                height: '24px',
                fontSize: '0.75rem',
                color: 'text.secondary',
                padding: '4px 0 4px 4px',
                backgroundColor: '#fff',
                borderBottom: '1px solid #ddd',
                zIndex: 1
              }}
            >
              {dateInfo.text}
            </Typography>
          </Box>
        ))}
      </Box>

      {/* Rental bars */}
      <Box sx={{ 
        position: 'absolute',
        top: '24px',
        left: 0,
        right: 0,
        bottom: 0
      }}>
        {rentals.map((rental) => {
          const rentalStart = moment(rental.leavingDate).startOf('day')
          const rentalEnd = moment(rental.returningDate).startOf('day')
          
          // Find start and end indices in the dateLabels array
          const startIndex = dateLabels.findIndex(d => d.date.isSame(rentalStart, 'day'))
          const endIndex = dateLabels.findIndex(d => d.date.isSame(rentalEnd, 'day'))
          
          if (startIndex === -1 || endIndex === -1) return null // Skip if dates not in range
          
          const left = (startIndex / dateLabels.length) * 100
          const width = ((endIndex - startIndex + 1) / dateLabels.length) * 100
          const top = rental.track * trackHeight

          return (
            <Box
              key={rental.id}
              sx={{
                position: 'absolute',
                left: `${left}%`,
                top: top,
                width: `${width}%`,
                minWidth: '30px',
                height: trackHeight - 2,
                backgroundColor: rental.type === 'invoice' ? '#bbdefb' : '#fff9c4',
                border: '1px solid',
                borderColor: rental.type === 'invoice' ? '#2196f3' : '#fdd835',
                opacity: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden',
                '&:hover': {
                  opacity: 0.9,
                  zIndex: 2
                }
              }}
            >
              <Box sx={{ 
                display: 'flex',
                flexDirection: width < 8 ? 'column' : 'row',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 0.2,
                px: 0.2,
                width: '100%',
                height: '100%',
                textAlign: 'center'
              }}>
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: width < 8 ? '0.6rem' : '0.75rem',
                    fontWeight: 'bold',
                    lineHeight: 1,
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  {rental.quantity} in
                </Typography>

                <Typography 
                  component="a"
                  href={`/invoices/edit/${rental.id}`}
                  target="_blank"
                  variant="caption"
                  sx={{ 
                    color: 'primary.main',
                    fontSize: width < 8 ? '0.6rem' : '0.75rem',
                    textDecoration: 'none',
                    lineHeight: 1,
                    display: 'flex',
                    alignItems: 'center',
                    '&:hover': {
                      textDecoration: 'underline'
                    }
                  }}
                >
                  #{rental.invoiceNumber}
                </Typography>
              </Box>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

export default ItemGanttChart 