import { MutableRefObject, useCallback, useEffect, useRef, useState } from "react";

import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import {
  InventoryItem,
} from "../../../app/interfaces/inventoryItem";
import {
  DataGridPro,
  GridApiPro,
  GridColDef,
  GridValidRowModel,
  gridFilteredSortedRowEntriesSelector,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  InputAdornment,
  TextField,
  Tooltip,
  alpha,
  useTheme,
} from "@mui/material";
import Iconify from "../../../app/iconify";
import { useResponsiveFontSize } from "../../../app/hooks/use-responsive-font";
import { SelectedItem } from "../../../app/interfaces/SelectedItem";
import _ from 'lodash';
import { green, red, yellow } from "@mui/material/colors";
import { OrganizationData } from "../../../app/interfaces/organizationData";
import { DailyInventoryStatus } from "../../../app/interfaces/dailyInventoryStatus";

// ----------------------------------------------------------------------

type Props = {
  orgData: OrganizationData
  title?: string;
  systemOS: string;
  inventory: InventoryItem[];
  // availability: any;
  peakInventory: DailyInventoryStatus | null;
  selectedItems: SelectedItem[];
  action?: React.ReactNode;
  //
  open: boolean;
  onClose: VoidFunction;
  //
  selected: (selectedId: string) => boolean;
  handleAddItem: (itemType: string, index: number | null, itemData: any, qty: number) => void;
  // handleAddDiscount: VoidFunction;
  handleAddFlatFee: VoidFunction;
  // handleAddDamageWaiver: VoidFunction;
  // handleAddPercentageFee: VoidFunction;
  // handleAddSubtotal: VoidFunction;
  handleAddLabelRow: VoidFunction;
  //
  createNewItem: VoidFunction;
  addAnItem: (item: InventoryItem, qty: number, insertAtIndex?: number) => void;
  replaceAnItem: (newItem: InventoryItem, oldItem: SelectedItem, keepQuantity: boolean, qty: number) => void;
  itemToReplace: SelectedItem | null;
  insertAtIndex?: number;

  onSelect: (item: InventoryItem | null) => void;
};

const debouncedSearch = _.debounce((query: string, apiRef: MutableRefObject<GridApiPro>) => {
  if (apiRef.current) {
    apiRef.current.setQuickFilterValues([query]);
  }
}, 300);

export default function InventoryPickerDialog({
  orgData,
  title = "Inventory",
  systemOS,
  inventory,
  // availability,
  peakInventory,
  selectedItems,
  action,
  //
  open,
  onClose,
  //
  selected,
  handleAddItem,
  // handleAddDiscount,
  handleAddFlatFee,
  // handleAddDamageWaiver,
  // handleAddPercentageFee,
  // handleAddSubtotal,
  handleAddLabelRow,

  createNewItem,
  addAnItem,
  replaceAnItem,
  itemToReplace,
  insertAtIndex,
  onSelect,
}: Props) {
  const theme = useTheme();
  const responsiveFontSize = useResponsiveFontSize();

  const apiRef = useGridApiRef();
  const [searchQuery, setSearchQuery] = useState("");
  const responseiveFontSize = useResponsiveFontSize();

  const [isNewItem] = useState(!itemToReplace);
  const [keepQuantity, setKeepQuantity] = useState(true);
  const [quantity, setQuantity] = useState(0);

  const quantityRef = useRef(quantity);
  useEffect(() => {
    quantityRef.current = quantity;
  }, [quantity]);

  const handleSelectInventoryItem = useCallback((item: InventoryItem | null, qty: number) => {
      if (isNewItem && item) {
        addAnItem(item, qty, insertAtIndex);
      } else if (item && itemToReplace) {
        replaceAnItem(item, itemToReplace, keepQuantity, qty);
      }
      searchQuery && setSearchQuery("");
      onClose();
    },
    [isNewItem, itemToReplace, searchQuery, onClose, addAnItem, replaceAnItem, keepQuantity]
  );

  const rentalAvailabilityNumber = (i: any) => {

    return 0;
    // setHasOverbooked(false);
    // if (!availability) {
    //   return null;
    // }

    // const availabilityItem = availability.find((a: any) => a.id === i.id) as any;

    // if (!availabilityItem) {
    //   return null;
    // }

    // const amount = availabilityItem.stock - availabilityItem.unavailableStock;

    // return amount;
  };

  const NumberCircle = ({ available, dailyAdjusted }: { available: number | null, dailyAdjusted: number | null }) => {

    if (dailyAdjusted === undefined || dailyAdjusted === null || isNaN(dailyAdjusted)) {
      return (
        <Tooltip title={"No availability data"}>
          <Typography fontWeight={"bold"} fontSize={responsiveFontSize - 4}>
            {`...`}
          </Typography>
        </Tooltip>
      );
    }

    const adjusted = dailyAdjusted;

    let backgroundColor;
    if (adjusted < 0) {
      backgroundColor = red[500];
    } else if (adjusted === 0) {
      backgroundColor = yellow[700];
    } else {
      backgroundColor = green[500];
    }

    let finalNumber = dailyAdjusted.toString();
    if (adjusted > 999) {
      finalNumber = "+1k";
    }

    return (
      <Tooltip title={`Available: ${dailyAdjusted}`}>
        <Stack direction="row" spacing={1} alignItems="center">
          {/* <Typography
            fontWeight={"bold"}
            fontSize={12}
          sx={{
            color: backgroundColor,
            }}
          >
            {finalNumber}
          </Typography> */}

          <Typography
            fontWeight={"bold"}
            fontSize={12}
          sx={{
            color: backgroundColor,
            }}
          >
            {finalNumber}
          </Typography>
        </Stack>

          
      </Tooltip>
    );
  };


  const columns: GridColDef<(typeof inventory)[number]>[] = [
    {
      field: "image",
      headerName: "",
      width: 30,
      editable: false,
      resizable: false,
      filterable: false,
      sortable: false,
      pinnable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const filteredSortedRowsAndIds = gridFilteredSortedRowEntriesSelector(apiRef);
        const rowIndex = params.api.getRowIndexRelativeToVisibleRows(params.id);
        const hasImage = params.row.image?.downloadURL;
        
        const ImageComponent = hasImage ? (
          <img src={params.row.image?.downloadURL} alt={params.row.name} width={30} height={30} />
        ) : null;
  
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            {ImageComponent}
          </Stack>
        );
      },
    },
    {
      field: "name",
      headerName: "Item",
      width: 500,
      editable: false,
      resizable: false,
      filterable: false,
      sortable: false,
      pinnable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const filteredSortedRowsAndIds = gridFilteredSortedRowEntriesSelector(apiRef);
        const rowIndex = params.api.getRowIndexRelativeToVisibleRows(params.id);
        const hasImage = params.row.image?.downloadURL;
        
        const ImageComponent = hasImage ? (
          <img src={params.row.image?.downloadURL} alt={params.row.name} width={30} height={30} />
        ) : null;
  
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            {/* {ImageComponent} */}
            <Typography variant="body2">{params.row.name}</Typography>
          </Stack>
        );
      },
    },
    {
      field: "availability",
      headerName: "Availability",
      width: 150,
      editable: false,
      filterable: false,
      sortable: false,
      pinnable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const available = rentalAvailabilityNumber(params.row);
        let dailyAdjusted = 0;
        
        if (peakInventory) {
          // Calculate quantity from direct items
          const directQuantity = selectedItems
            .filter(i => i.id === params.row.id)
            .reduce((total, item) => total + (item.selectedQty || 0), 0);

          // Calculate quantity from bundle items
          let bundleQuantity = 0;
          selectedItems.forEach((si) => {
            if (si.type === 'bundle' && si.bundleItems) {
              si.bundleItems.forEach((bundleItem) => {
                if (bundleItem.bundleItemId === params.row.id) {
                  bundleQuantity += (bundleItem.bundleItemQty || 0) * si.selectedQty;
                }
              });
            }
          });

          dailyAdjusted = (params.row.stock ?? 0) -
            (peakInventory.items[params.row.id]?.outByInvoice ?? 0) -
            directQuantity -
            bundleQuantity -
            quantity;
        }
  
        return (
          <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
            <NumberCircle available={available} dailyAdjusted={dailyAdjusted} />
          </Stack>
        );
      },
    },
    {
      field: "shortcut",
      headerName: "Shortcut",
      width: 150,
      editable: false,
      filterable: false,
      sortable: false,
      pinnable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const rowIndex = params.api.getRowIndexRelativeToVisibleRows(params.id);
        let shortcutLabel;
  
        if (rowIndex === 0) {
          shortcutLabel = "Enter"; // Enter key for the first row
        } else if (rowIndex < 9) {
          shortcutLabel = rowIndex + 1; // Numbers 1 - 9 for other rows
        } else {
          shortcutLabel = null; // No shortcut for other rows
        }
  
        return shortcutLabel ? (
          <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
            <Tooltip
              title={`${systemOS === "mac" ? `⌘ ${shortcutLabel}` : `ctrl ${shortcutLabel}`}`}
              arrow
            >
              <Chip label={shortcutLabel} size="small" />
            </Tooltip>
          </Stack>
        ) : null; // Return null if there's no shortcut
      },
    },
  ];


  useEffect(() => {
    const handleCommandEnter = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        event.preventDefault();

        const filteredSortedRowsAndIds =
          gridFilteredSortedRowEntriesSelector(apiRef);

        handleSelectInventoryItem(
          filteredSortedRowsAndIds[0].model as InventoryItem, quantityRef.current
        );
      } else if (event.key >= "1" && event.key <= "9" && (event.metaKey || event.ctrlKey)) {

        event.preventDefault();

        const index = parseInt(event.key, 10) - 1;
        const filteredSortedRowsAndIds = gridFilteredSortedRowEntriesSelector(
          apiRef.current.state
        );

        if (
          filteredSortedRowsAndIds &&
          index < filteredSortedRowsAndIds.length
        ) {
          handleSelectInventoryItem(
            filteredSortedRowsAndIds[index].model as InventoryItem, quantityRef.current
          );
        }
      }
    };

    if (open) {
      window.addEventListener("keydown", handleCommandEnter);
    }
    return () => {
      window.removeEventListener("keydown", handleCommandEnter);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  // const handleSearchQuery = useCallback(
  //   (event: React.ChangeEvent<HTMLInputElement>) => {
  //     setSearchQuery(event.target.value);
  //     apiRef.current?.setQuickFilterValues([event.target.value]);
  //   },
  //   [apiRef]
  // );

  // const debouncedSearch = useCallback(
  //   _.debounce((query: string) => {
  //     if (apiRef.current) {
  //       apiRef.current.setQuickFilterValues([query]);
  //     }
  //   }, 300), // Adjust the debounce delay as needed
  //   [apiRef]
  // );

  const handleSearchQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query);
    debouncedSearch(query, apiRef);
  };


  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      {!isNewItem && (
        <Box bgcolor={alpha(theme.palette.warning.lighter, 0.8)} borderRadius={2} padding={2} margin={1}>
          <Typography>{`You are replacing "${itemToReplace?.name}" with a new item you select below.`}</Typography>

          <FormControlLabel
            sx={{ float: "right" }}
            control={
              <Checkbox
                inputProps={{ style: { fontSize: responsiveFontSize } }}
                checked={keepQuantity}
                onChange={(e) => {
                  setKeepQuantity(e.target.checked);
                }}
              />
            }
            label={<Typography fontSize={responsiveFontSize}>{`Keep Quantity (${itemToReplace?.selectedQty})`}</Typography>}
          />
        </Box>
      )}

      {isNewItem && (
        <Stack direction={"row"} gap={1} sx={{ p: 1 }}>
          <Button
            size="small"
            variant="outlined"
            onClick={() => handleAddItem("subtotal", null, null, 1)}
            startIcon={
              <Iconify
                icon="fluent:add-12-regular"
                width={16}
                sx={{
                  paddingRight: "0px",
                  marginRight: "0px",
                }}
              />
            }
            sx={{
              fontSize: responseiveFontSize - 2,
            }}
          >{`Subtotal`}</Button>

          {orgData?.integrations?.qbOnline !== true && (
            <Button
              size="small"
              variant="outlined"
              onClick={() => handleAddItem("descriptionOnly", null, null, 1)}
              startIcon={
                <Iconify
                  icon="fluent:add-12-regular"
                  width={16}
                  sx={{
                    paddingRight: "0px",
                    marginRight: "0px",
                  }}
                />
              }
              sx={{
                fontSize: responseiveFontSize - 2,
              }}
            >{`Label`}</Button>
          )}
          
          {/*
          <Button
            size="small"
            variant="outlined"
            onClick={() => handleAddItem("damageWaiver", null, null, 1)}
            startIcon={
              <Iconify
                icon="fluent:add-12-regular"
                width={16}
                sx={{
                  paddingRight: "0px",
                  marginRight: "0px",
                }}
              />
            }
            sx={{
              fontSize: responseiveFontSize - 2,
            }}
          >{`Damage Waiver`}</Button>

          <Button
            size="small"
            variant="outlined"
            onClick={() => handleAddItem("percentFee", null, null, 1)}
            startIcon={
              <Iconify
                icon="fluent:add-12-regular"
                width={16}
                sx={{
                  paddingRight: "0px",
                  marginRight: "0px",
                }}
              />
            }
            sx={{
              fontSize: responseiveFontSize - 2,
            }}
          >{`Percent Fee`}</Button> */}

          {orgData?.integrations?.qbOnline !== true && (
            <Button
              size="small"
              variant="outlined"
              onClick={() => handleAddItem("flatFee", null, null, 1)}
              startIcon={
                <Iconify
                  icon="fluent:add-12-regular"
                  width={16}
                  sx={{
                    paddingRight: "0px",
                    marginRight: "0px",
                  }}
                />
              }
              sx={{
                fontSize: responseiveFontSize - 2,
              }}
            >{`Flat Fee`}</Button>
          )}

          {/* <Button
            size="small"
            variant="outlined"
            onClick={handleAddDiscount}
            startIcon={
              <Iconify
                icon="fluent:add-12-regular"
                width={16}
                sx={{
                  paddingRight: "0px",
                  marginRight: "0px",
                }}
              />
            }
            sx={{
              fontSize: responseiveFontSize - 2,
            }}
          >{`Discount`}</Button> */}
        </Stack>
      )}

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 2, pr: 1.5 }}>
        <Typography variant="h6"> {title} </Typography>

        {action && isNewItem && action}
      </Stack>

      <Stack direction={"row"}>
        <TextField
          size="small"
          sx={{ mx: 2 }}
          value={searchQuery}
          onChange={handleSearchQuery}
          placeholder="Search..."
          autoFocus={true}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: "text.disabled" }} />
              </InputAdornment>
            ),
          }}
        />

        <TextField
          size="small"
          label="Quantity"
          type="number"
          sx={{ mx: 2, width: "150px" }}
          value={quantity}
          onChange={(e) => setQuantity(parseInt(e.target.value))}
        />
      </Stack>

      {open && (
        <DataGridPro
          apiRef={apiRef}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          columns={columns}
          rows={inventory}
          loading={inventory.length === 0}
          rowHeight={38}
          onRowClick={(row) => handleSelectInventoryItem(row.row as InventoryItem, quantity)}
          disableRowSelectionOnClick
          sx={{
            minHeight: "400px",
            p: 1,
            borderTopWidth: 0,
            "& .MuiDataGrid-row": {
              cursor: "pointer",
            },
          }}
        />
      )}
    </Dialog>
  );
}
