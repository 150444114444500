// The plans or tiers are: basics, essentials, plus, premium
import { OrganizationData } from "./organizationData";

export const featuresPermissionSet: FeaturesPermissionSet = {
    dashboard: {
        emailTemplates: {
            minimumRequiredTier: 'plus',
        },
        automation: {
            minimumRequiredTier: 'premium',
        },
    },
    checkAvailability: {
        minimumRequiredTier: 'basics',
    },
    makeTheSale: {
        savedFilters: {
            minimumRequiredTier: 'premium',
        },
        emailTemplates: {
            minimumRequiredTier: 'plus',
        },
    },
    prepareTheItems: {
        minimumRequiredTier: 'essentials',
    },
    routing: {
        minimumRequiredTier: 'plus',
    },
    reports: {
        sales: {
            minimumRequiredTier: 'basics',
        },
        tax: {
            minimumRequiredTier: 'basics',
        },
        usage: {
            minimumRequiredTier: 'essentials',
        },
        depreciation: {
            minimumRequiredTier: 'essentials',
        },
    },
    inventory: {
        maintenance: {
            minimumRequiredTier: 'plus',
        },
        bundles: {
            minimumRequiredTier: 'plus',
        },
        depreciation: {
            minimumRequiredTier: 'essentials',
        },
    },
    vendors: {
        minimumRequiredTier: 'essentials',
    },
    venues: {
        minimumRequiredTier: 'essentials',
    },
    trucks: {
        minimumRequiredTier: 'plus',
    },
    fileManager: {
        minimumRequiredTier: 'plus',
    },
    internalNotes: {
        minimumRequiredTier: 'plus',
    },
    integrations: {
        qbo: {
            minimumRequiredTier: 'plus',
        },
    },
    settings: {
        tags: {
            minimumRequiredTier: 'essentials',
        },
    },
    email: {
        templates: {
            minimumRequiredTier: 'plus',
        },
        automations: {
            minimumRequiredTier: 'premium',
        },
    },
    invoiceCustomization: {
        minimumRequiredTier: 'essentials',
    },
    embeddedCheckout: {
        wishlist: {
            minimumRequiredTier: 'plus',
        },
    },
};



const tierLevels = {
    basics: 1,
    essentials: 2,
    plus: 3,
    premium: 4,
};

// Helper function to find the minimum required tier for a feature
export function findFeatureTier(features: any, path: string) {
    const keys = path.split('.'); // Assuming the feature is specified in a dot-separated path
    let currentLevel = features;

    for (const key of keys) {
        if (currentLevel[key]) {
            if (currentLevel[key].minimumRequiredTier) {
                return currentLevel[key].minimumRequiredTier;
            } else {
                currentLevel = currentLevel[key]; // Dive deeper into the next level
            }
        } else {
            return null; 
        }
    }

    return null; // In case the loop completes without finding a tier (shouldn't happen with correct input)
}

export function checkSubscriptionLevel(feature: string, orgData: OrganizationData) {

    return "premium";


    // const tier: Subscription | undefined = orgData?.subscription;
    // if (!tier) {
    //     console.error('Subscription not found');
    //     return false;
    // }

    // const requiredTier = findFeatureTier(featuresPermissionSet, feature);

    // if (!requiredTier) {
    //     console.error('Feature not found');
    //     return false;
    // }

    // const result = tierLevels[tier as keyof typeof tierLevels] >= tierLevels[requiredTier as keyof typeof tierLevels];

    // return result;  
}

type Subscription = 'plus' | 'premium' | 'basics' | 'essentials';

type FeaturePermission = {
  minimumRequiredTier: Subscription;
};

type NestedFeaturePermissions = {
  [key: string]: FeaturePermission;
};

export type FeaturesPermissionSet = {
  dashboard: NestedFeaturePermissions;
  checkAvailability: FeaturePermission;
  makeTheSale: NestedFeaturePermissions;
  prepareTheItems: FeaturePermission;
  routing: FeaturePermission;
  reports: NestedFeaturePermissions;
  inventory: NestedFeaturePermissions;
  vendors: FeaturePermission;
  venues: FeaturePermission;
  trucks: FeaturePermission;
  fileManager: FeaturePermission;
  internalNotes: FeaturePermission;
  integrations: NestedFeaturePermissions;
  settings: NestedFeaturePermissions;
  email: NestedFeaturePermissions;
  invoiceCustomization: FeaturePermission;
  embeddedCheckout: NestedFeaturePermissions;
};
