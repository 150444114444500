import React, { useState, useEffect } from "react";
import { Popover, Space, Tag, Tooltip } from "antd";
import { Stack } from "@mui/material";
import {
  INV_BUNDLE_ITEM,
  INV_BUNDLE_ITEM_DESC,
  INV_BUNDLE_ITEM_NAME,
  INV_BUNDLE_ITEM_QTY,
} from "../../../app/utils/models/modelConstants/modelConstants";
import { CategoryTag } from "../../../app/system-components/tags/Tags";
import {
  COLOR_DARK_BG_0,
  COLOR_LIGHT_GRAY,
  COLOR_RED_0,
  COLOR_YELLOW_0,
} from "../../../app/ui/colorConstants";
import TextBody from "../../../app/system-components/typography/text/TextBody";
import TextSubtitle from "../../../app/system-components/typography/text/TextSubtitle";
import { capitalizeFirstLetter } from "../../../app/utils/casing/capitalizeFirstLetter";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Typography } from "antd";

const { Text } = Typography;

// Helper function to calculate bundle item availability and styling
const calculateBundleItemAvailability = ({ 
  bundleItem, 
  bundleItemQty, 
  availableStock = 0 
}) => {
  let backgroundColor, color;

  if (availableStock === 0) {
    backgroundColor = COLOR_YELLOW_0;
    color = "#FFF";
  } else if (availableStock < bundleItemQty) {
    backgroundColor = COLOR_RED_0;
    color = "#FFF";
  } else {
    backgroundColor = COLOR_LIGHT_GRAY;
    color = COLOR_DARK_BG_0;
  }

  return {
    availableStock,
    backgroundColor,
    color
  };
};

const TagTypeWithTooltip = ({ item, peakInventory, availableInventory, getDailyStatusAvailability, loadingDailyStatus, showOldMethod }) => {
  
  const [bundleContainsUnavail, setBundleContainsUnavail] = useState(false);
  const [bundleContainsAtCapacity, setBundleContainsAtCapacity] = useState(false);
  const [bundleContainsUnavailNew, setBundleContainsUnavailNew] = useState(false);
  const [bundleContainsAtCapacityNew, setBundleContainsAtCapacityNew] = useState(false);


  useEffect(() => {
    if (item.type === INV_BUNDLE_ITEM) {
      const containsUnavail = item.bundleItems.some((b) => {
        const bundleItem = availableInventory?.find((i) => {
          return i.id === b.bundleItemId;
        });
        const availableStock = bundleItem?.availableStock ?? 0;
        return availableStock < 0;
      });
      const containsAtCapacity = item.bundleItems.some((b) => {
        const bundleItem = availableInventory?.find((i) => {
          return i.id === b.bundleItemId;
        });
        const bundleItemQty = b?.bundleItemQty ?? 0;
        const availableStock = bundleItem?.availableStock ?? 0;
        return availableStock === 0;
      });

      setBundleContainsUnavail(containsUnavail);
      setBundleContainsAtCapacity(containsAtCapacity);
    }
  }, [item, availableInventory]);

  useEffect(() => {
    if (item.type === "bundle") {

      // console.log('Peak inventory:', peakInventory);
      // console.log('Available inventory:', availableInventory);
      // console.log('ITEM ITEM ITEM:', item);
     
      // New method
      const containsUnavailNew = item.bundleItems.some((b) => {
        const bundleItem = availableInventory?.find((i) => i.id === b.bundleItemId);
        if (!bundleItem) return false;
        const availability = getDailyStatusAvailability(bundleItem);
        return availability?.available < 0;
      });

      const containsAtCapacityNew = item.bundleItems.some((b) => {
        const bundleItem = availableInventory?.find((i) => i.id === b.bundleItemId);
        if (!bundleItem) return false;
        const availability = getDailyStatusAvailability(bundleItem);
        return availability?.available === 0;
      });

      setBundleContainsUnavailNew(containsUnavailNew);
      setBundleContainsAtCapacityNew(containsAtCapacityNew);
    }
  }, [item, availableInventory, getDailyStatusAvailability]);

  const bundleItems = (item) => {
    return (
      <Popover
        placement="right"
        title={"Items in bundle"}
        content={() => bundlePopover(item)}
        trigger="hover"
      >
        <CategoryTag
          style={{ margin: 0, fontSize: "13px", cursor: "pointer" }}
          text={
            <span>
              {(bundleContainsUnavail || bundleContainsUnavailNew) && (
                <ExclamationCircleFilled
                  style={{ color: COLOR_RED_0, marginRight: "8px" }}
                />
              )}
              Bundle
            </span>
          }
        />
      </Popover>
    );
  };

  // MARK: Bundle Popover
  const bundlePopover = (item) => {
    return (
      <div>
        {/* Current Method Section */}
        {showOldMethod && (
        <div>
          <Text strong style={{ fontSize: "14px", marginBottom: "8px", display: "block" }}>
            Current Method
          </Text>
          <Stack spacing={1}>
            {item.bundleItems.map((b, index) => {
              const bundleItem = availableInventory?.find((i) => i.id === b.bundleItemId);
              const bundleItemQty = b?.bundleItemQty ?? 0;
              const availableStock = bundleItem?.availableStock ?? 0;
              
              const { backgroundColor, color } = calculateBundleItemAvailability({
                bundleItem,
                bundleItemQty,
                availableStock
              });

              return (
                <div key={index}>
                  <Space>
                    <CategoryTag
                      style={{
                        backgroundColor,
                        margin: "2px 4px 2px 0px",
                        color,
                        borderColor: "transparent",
                      }}
                      text={`${b[INV_BUNDLE_ITEM_QTY]}x`}
                    />
                    <TextBody text={b[INV_BUNDLE_ITEM_NAME]} />
                    <TextSubtitle text={b[INV_BUNDLE_ITEM_DESC]} />
                    <TextSubtitle text={`(${availableStock} left)`} />
                  </Space>
                </div>
              );
            })}

          </Stack>
          {bundleContainsUnavail && (
            <Text
              style={{
                fontSize: "13px",
                color: COLOR_RED_0,
                display: "block",
                marginTop: "8px"
              }}
            >
              Some items are overbooked during the selected range
            </Text>
          )}
          {bundleContainsAtCapacity && !bundleContainsUnavail && (
            <Text
              style={{
                fontSize: "13px",
                color: COLOR_YELLOW_0,
                display: "block",
                marginTop: "8px"
              }}
            >
              Some items are at capacity during the selected range
            </Text>
          )}
        </div>
        )}

        <div style={{ marginTop: showOldMethod ? "16px" : "0px", borderTop: showOldMethod ? "1px solid #f0f0f0" : "none", paddingTop: showOldMethod ? "16px" : "0px" }}>
          {showOldMethod && (
          <Text strong style={{ fontSize: "14px", marginBottom: "8px", display: "block" }}>
            New Method
          </Text>
          )}
          {loadingDailyStatus ? (
            <Text>Loading availability data...</Text>
          ) : (
            <>
              <Stack spacing={1}>
                {item.bundleItems.map((b, index) => {
                  const bundleItem = availableInventory?.find((i) => i.id === b.bundleItemId);
                  const bundleItemQty = b?.bundleItemQty ?? 0;
                  const newAvailability = getDailyStatusAvailability(bundleItem);
                  const newAvailableStock = newAvailability?.available ?? 0;
                  
                  const { backgroundColor, color } = calculateBundleItemAvailability({
                    bundleItem,
                    bundleItemQty,
                    availableStock: newAvailableStock
                  });

                  return (
                    <div key={index}>
                      <Space>
                        <CategoryTag
                          style={{
                            backgroundColor,
                            margin: "2px 4px 2px 0px",
                            color,
                            borderColor: "transparent",
                          }}
                          text={`${b[INV_BUNDLE_ITEM_QTY]}x`}
                        />
                        <TextBody text={b[INV_BUNDLE_ITEM_NAME]} />
                        <TextSubtitle text={b[INV_BUNDLE_ITEM_DESC]} />
                        <TextSubtitle text={`(${newAvailableStock} left)`} />
                      </Space>
                    </div>
                  );
                })}
              </Stack>
              {bundleContainsUnavailNew && (
                <Text
                  style={{
                    fontSize: "13px",
                    color: COLOR_RED_0,
                    display: "block",
                    marginTop: "8px"
                  }}
                >
                  Some items are overbooked during the selected range
                </Text>
              )}
              {bundleContainsAtCapacityNew && !bundleContainsUnavailNew && (
                <Text
                  style={{
                    fontSize: "13px",
                    color: COLOR_YELLOW_0,
                    display: "block",
                    marginTop: "8px"
                  }}
                >
                  Some items are at capacity during the selected range
                </Text>
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  const regularTag = () => {
    return <Tag>{capitalizeFirstLetter(item.type)}</Tag>;
  };

  return <div>{item?.type === INV_BUNDLE_ITEM ? bundleItems(item) : regularTag()}</div>;
};

export default TagTypeWithTooltip;