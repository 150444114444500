import { lazy, Suspense, useCallback, useMemo, useState } from "react";
import "../../App.less";
import { Affix, Button, PageHeader, Space } from "antd";
import { useFirestore } from "react-redux-firebase";
import { authSelector } from "../auth/authSlice";
import { useSelector } from "react-redux";
import { ROUTE_ADMIN_CREATE_INVENTORY } from "../../app/routes";
import InventoryDataGrid from "./components/InventoryDataGrid";
import { Box } from "@mui/material";

const ItemDrawer = lazy(() => import("./components/ItemDrawer/ItemDrawer"));

const AdminInventory = ({ history, inventory, loadInventory, loadingInventory, categories }) => {
  const firestore = useFirestore();

  const { orgData, userData, fsOrgPrefix } = useSelector(authSelector);

  const [itemDrawer, setItemDrawer] = useState({
    visible: false,
    item: null,
  });

  // Memoized handler for setting itemDrawer state
  const handleSetItemDrawer = useCallback((drawerState) => {
    setItemDrawer(drawerState);
  }, []);

  const handleItemDrawerComplete = useCallback(() => {
    setItemDrawer({ visible: false, item: null });
    loadInventory();
  }, [loadInventory]);

  // Memoize the extra buttons for PageHeader
  const pageHeaderExtra = useMemo(
    () => (
      <Space size={12}>
        <Button type="primary" onClick={() => history.push(ROUTE_ADMIN_CREATE_INVENTORY)}>
          Add Inventory Item
        </Button>
      </Space>
    ),
    [history]
  );

  return (
    <div>
      <Affix>
        <PageHeader className="PageHeader" backIcon={false} title="Inventory" extra={pageHeaderExtra} />
      </Affix>
      <Box 
       sx={{
        display: "flex",
        flexDirection: "column",
        margin: "16px",
        borderRadius: "8px",
        backgroundColor: "white",

      }}>
        {inventory && (
          <InventoryDataGrid
            setItemDrawer={handleSetItemDrawer}
            onDuplicateComplete={loadInventory}
            onDeleteComplete={loadInventory}
            data={inventory}
            onEditComplete={loadInventory}
            loadingInventory={loadingInventory}
          />
        )}
        
      </Box>
      <Suspense fallback={<div>Loading Item Details...</div>}>
          <ItemDrawer
            visible={itemDrawer.visible}
            handleCloseDrawer={setItemDrawer}
            item={itemDrawer}
            categories={categories}
            onComplete={handleItemDrawerComplete}
            userData={userData}
            orgData={orgData}
            fsOrgPrefix={fsOrgPrefix}
          />
        </Suspense>
    </div>
  );
};

export default AdminInventory;
