import {
  VENUE_CONTACT_NAME,
  VENUE_NAME,
  VENUE_ADDRESS,
  VENUE_CITY,
  VENUE_STATE,
  VENUE_ZIP,
  VENUE_FULL_ADDRESS_STR,
  VENUE_NOTES,
} from "../modelConstants/modelConstants";
import { checkUndefined } from "./checkUndefined";
import notificationError from "../../../system-components/toasters/notificationError";

export const checkVenue = (snap) => {
  const data = snap.data();
  const exists = checkUndefined(data, null);
  if (!exists)
    return notificationError(
      "Record could not be found",
      "Please contact support for assistance"
    );
  return {
    id: snap.id,
    orgId: checkUndefined(data.orgId, ""),
    [VENUE_CONTACT_NAME]: checkUndefined(data[VENUE_CONTACT_NAME], ""),
    [VENUE_NOTES]: checkUndefined(data[VENUE_NOTES], ""),
    [VENUE_NAME]: checkUndefined(data[VENUE_NAME], ""),
    [VENUE_ADDRESS]: checkUndefined(data[VENUE_ADDRESS], ""),
    [VENUE_CITY]: checkUndefined(data[VENUE_CITY], ""),
    [VENUE_STATE]: checkUndefined(data[VENUE_STATE], ""),
    [VENUE_ZIP]: checkUndefined(data[VENUE_ZIP], ""),
    [VENUE_FULL_ADDRESS_STR]: checkUndefined(data[VENUE_FULL_ADDRESS_STR], ""),
    createdBy: checkUndefined(data.createdBy, ""),
    createdOn: checkUndefined(data.createdOn, null),
    updatedBy: checkUndefined(data.updatedBy, ""),
    updatedOn: checkUndefined(data.updatedOn, null),
    isActive: checkUndefined(data.isActive, true),
    internalNotes: checkUndefined(data.internalNotes, ""),
    website: checkUndefined(data.website, ""),
    phone: checkUndefined(data.phone, ""),
    deliveryFee: checkUndefined(data.deliveryFee, undefined),
  };
};
