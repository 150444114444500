import { Menu, Layout, Typography, Modal } from "antd";
import "../../App.less";
import { i18n } from "../../i18n";
import { COLOR_DARK_BG_1, COLOR_ORANGE } from "../../app/ui/colorConstants";
import {
  AppstoreOutlined,
  CalendarOutlined,
  CodeSandboxOutlined,
  CompassOutlined,
  DashboardOutlined,
  FileTextOutlined,
  FireOutlined,
  PieChartOutlined,
  SettingOutlined,
  UserOutlined,
  MobileOutlined,
  LockOutlined,
  ClockCircleFilled,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import {
  ROUTE_ADMIN_INTEGRATIONS,
  ROUTE_ADMIN_BILLING,
  ROUTE_ADMIN_INVENTORY,
  ROUTE_ADMIN_SETTINGS_GENERAL,
  ROUTE_ADMIN_SETTINGS_WORDS,
  ROUTE_ADMIN_SETTINGS_EMAIL,
  ROUTE_ADMIN_USERS,
  ROUTE_ADMIN_VENUES,
  ROUTE_INVENTORY,
  ROUTE_INVOICES,
  ROUTE_QUICK_REPORT,
  ROUTE_ROUTES,
  ROUTE_ADMIN_CUSTOMERS,
  ROUTE_ADMIN_TRUCKS,
  ROUTE_ADMIN_FILE_MANAGER,
  ROUTE_REPORTS_SALES,
  ROUTE_REPORTS_USAGE,
  ROUTE_REPORTS_DEPRECIATION,
  ROUTE_REPORTS_TAX,
  ROUTE_ADMIN_EMBEDDED_CHECKOUT,
  ROUTE_ADMIN_VENDORS,
  ROUTE_DASHBOARD,
  ROUTE_CHANGE_LOG,
  ROUTE_MOBILE_APPS,
  ROUTE_ADMIN_SETTINGS_PERMISSIONS,
} from "../../app/routes";
import ProfileDropdown from "./components/ProfileDropdown";
import { useSidebar } from "../../app/services/hooks/useSidebar";
import { useContext, useEffect, useState } from "react";
import {
  hasPermission,
} from "../admin-permissions/usePermissions";
import WeatherContext from "../sharedComponents/weather/context/weather-context";
import { checkSubscriptionLevel } from "../../app/interfaces/features-set-permissions";
import { useSelector } from "react-redux";
import { authSelector } from "../auth/authSlice";
import { Chip } from "@mui/material";

const { Sider } = Layout;
const { Title } = Typography;
const { SubMenu } = Menu;

const Sidebar = ({ userData, permissionsData }) => {
  const { highlighted, blockSidebar } = useSidebar();
  const { orgData } = useSelector(authSelector);

  const history = useHistory();

  const [collapsed, setCollapsed] = useState(false);
  const [daysUntilTrial, setDaysUntilTrial] = useState(0);

  const weatherContext = useContext(WeatherContext);

  useEffect(() => {
    if (orgData && orgData.createdOn) {
      if (orgData.subscriptionStatus !== "trial") {
        return;
      }

      const orgCreatedOn = orgData.createdOn.toDate();
      const currentDate = new Date();
      const diffTime = Math.abs(currentDate - orgCreatedOn);
      const diffDays = 14 - Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      setDaysUntilTrial(diffDays);
    }
  }, [orgData]);

  useEffect(() => {
    if (weatherContext && orgData) {
      weatherContext.fetchFiveDayWeather(orgData.lat, orgData.long)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgData])

  const handleClick = (route) => {
    if (!blockSidebar) {
      history.push(route);
    } else {
      // modal
      Modal.confirm({
        title: "Exit without saving?",
        content: "",
        onOk() {
          history.push(route);
        },
        onCancel() {},
      });
    }
  };

  return orgData && orgData ? (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      breakpoint="xl"
      width={256}
      style={{
        backgroundColor: COLOR_DARK_BG_1,
      }}
    >
      <div style={{ height: "calc(100vh - 100px)", overflowY: "auto" }}>
        <Menu
          style={{
            backgroundColor: COLOR_DARK_BG_1,
          }}
          selectedKeys={highlighted}
          defaultOpenKeys={["subMenu"]}
          mode={"inline"}
          theme={"dark"}
          inlineCollapsed={collapsed}
        >
          <Menu.Item
            key={"company_header"}
            className="CompanyHeader"
            style={{
              height: "100px",
            }}
            icon={collapsed ? <UserOutlined /> : ""}
          >
            <Title level={4} style={{ color: "#FFF", pointerEvents: "none" }}>
              RentKit
            </Title>
            <ProfileDropdown history={history} />
          </Menu.Item>

          {orgData.subscriptionStatus === "trial" && (
            <>
              <Menu.Item
                key={"days_left"}
                style={{
                  height: "25px",
                  marginBottom: "0px",
                  paddingLeft: "24px",
                }}
              >
                {collapsed ? null : (
                  <Title
                    style={{
                      color: COLOR_ORANGE,
                      pointerEvents: "none",
                      fontSize: 16,
                    }}
                  >
                    {daysUntilTrial > 1
                      ? `${daysUntilTrial} Days Left On Trial`
                      : `${daysUntilTrial} Day Left On Trial`}
                  </Title>
                )}
              </Menu.Item>

              <Menu.Item
                key={"trial_header_cta"}
                icon={collapsed ? <ClockCircleFilled /> : ""}
                onClick={() => handleClick(ROUTE_ADMIN_BILLING)}
              >
                <Chip
                  // do check for days  vs day for number
                  label={"Change To Paid Plan"}
                  sx={{
                    float: "center",
                    backgroundColor: "lightgray",
                    color: "black",
                  }}
                />
              </Menu.Item>
            </>
          )}

          <Menu.Item
            onClick={() => handleClick(ROUTE_DASHBOARD)}
            key={ROUTE_DASHBOARD}
            icon={<DashboardOutlined />}
          >
            Dashboard
          </Menu.Item>

          {hasPermission(ROUTE_INVENTORY, userData, permissionsData) && (
            <Menu.Item
              onClick={() => handleClick(ROUTE_INVENTORY)}
              key={ROUTE_INVENTORY}
              icon={<CodeSandboxOutlined />}
            >
              {i18n("sidebar.availability")}
            </Menu.Item>
          )}

          {hasPermission(ROUTE_INVOICES, userData, permissionsData) && (
            <Menu.Item
              onClick={() => handleClick(ROUTE_INVOICES)}
              key={ROUTE_INVOICES}
              icon={<FileTextOutlined />}
            >
              {i18n("sidebar.invoices")}
            </Menu.Item>
          )}

          {hasPermission(ROUTE_QUICK_REPORT, userData, permissionsData) && (
            <Menu.Item
              onClick={() => handleClick(ROUTE_QUICK_REPORT)}
              key={ROUTE_QUICK_REPORT}
              icon={
                checkSubscriptionLevel("prepareTheItems", orgData) ? (
                  <CalendarOutlined />
                ) : (
                  <LockOutlined />
                )
              }
              style={{
                color: checkSubscriptionLevel("prepareTheItems", orgData)
                  ? "white"
                  : "gray",
              }}
            >
              {i18n("sidebar.prepare")}
            </Menu.Item>
          )}

          {hasPermission(ROUTE_ROUTES, userData, permissionsData) && (
            <Menu.Item
              onClick={() => handleClick(ROUTE_ROUTES)}
              key={ROUTE_ROUTES}
              icon={
                checkSubscriptionLevel("routing", orgData) ? (
                  <CompassOutlined />
                ) : (
                  <LockOutlined />
                )
              }
              style={{
                color: checkSubscriptionLevel("routing", orgData)
                  ? "white"
                  : "gray",
              }}
            >
              {i18n("sidebar.routes")}
            </Menu.Item>
          )}

          <SubMenu
            key="reportsSubMenu"
            icon={<PieChartOutlined />}
            title={i18n("sidebar.reports.title")}
          >
            {hasPermission(ROUTE_REPORTS_SALES, userData, permissionsData) && (
              <Menu.Item
                onClick={() => handleClick(ROUTE_REPORTS_SALES)}
                key={ROUTE_REPORTS_SALES}
              >
                {i18n("sidebar.reports.sales")}
              </Menu.Item>
            )}

            {hasPermission(ROUTE_REPORTS_SALES, userData, permissionsData) && (
              <Menu.Item
                onClick={() => handleClick(ROUTE_REPORTS_TAX)}
                key={ROUTE_REPORTS_TAX}
              >
                {i18n("sidebar.reports.tax")}
              </Menu.Item>
            )}

            {hasPermission(ROUTE_REPORTS_USAGE, userData, permissionsData) && (
              <Menu.Item
                onClick={() => handleClick(ROUTE_REPORTS_USAGE)}
                icon={
                  checkSubscriptionLevel("reports.usage", orgData) ? null : (
                    <LockOutlined />
                  )
                }
                style={{
                  color: checkSubscriptionLevel("reports.usage", orgData)
                    ? "white"
                    : "gray",
                }}
                key={ROUTE_REPORTS_USAGE}
              >
                {i18n("sidebar.reports.usage")}
              </Menu.Item>
            )}

            {hasPermission(
              ROUTE_REPORTS_DEPRECIATION,
              userData,
              permissionsData
            ) && (
              <Menu.Item
                onClick={() => handleClick(ROUTE_REPORTS_DEPRECIATION)}
                icon={
                  checkSubscriptionLevel(
                    "reports.depreciation",
                    orgData
                  ) ? null : (
                    <LockOutlined />
                  )
                }
                style={{
                  color: checkSubscriptionLevel("reports.depreciation", orgData)
                    ? "white"
                    : "gray",
                }}
                key={ROUTE_REPORTS_DEPRECIATION}
              >
                {i18n("sidebar.reports.depreciation")}
              </Menu.Item>
            )}
          </SubMenu>

          <SubMenu
            key="subMenu"
            icon={<AppstoreOutlined />}
            title={i18n("sidebar.admin.title")}
          >
            {hasPermission(ROUTE_ADMIN_USERS, userData, permissionsData) && (
              <Menu.Item
                onClick={() => handleClick(ROUTE_ADMIN_USERS)}
                key={ROUTE_ADMIN_USERS}
              >
                {i18n("sidebar.admin.users")}
              </Menu.Item>
            )}

            {hasPermission(
              ROUTE_ADMIN_INVENTORY,
              userData,
              permissionsData
            ) && (
              <Menu.Item
                onClick={() => handleClick(ROUTE_ADMIN_INVENTORY)}
                key={ROUTE_ADMIN_INVENTORY}
              >
                {i18n("sidebar.admin.inventory")}
              </Menu.Item>
            )}

            {hasPermission(
              ROUTE_ADMIN_CUSTOMERS,
              userData,
              permissionsData
            ) && (
              <Menu.Item
                onClick={() => handleClick(ROUTE_ADMIN_CUSTOMERS)}
                key={ROUTE_ADMIN_CUSTOMERS}
              >
                {i18n("sidebar.admin.customers")}
              </Menu.Item>
            )}

            {orgData.allowInventoryMaintenance &&
              hasPermission(ROUTE_ADMIN_VENDORS, userData, permissionsData) && (
                <Menu.Item
                  onClick={() => handleClick(ROUTE_ADMIN_VENDORS)}
                  icon={
                    checkSubscriptionLevel("vendors", orgData) ? null : (
                      <LockOutlined />
                    )
                  }
                  style={{
                    color: checkSubscriptionLevel("vendors", orgData)
                      ? "white"
                      : "gray",
                  }}
                  key={ROUTE_ADMIN_VENDORS}
                >
                  Vendors
                </Menu.Item>
              )}

            {hasPermission(ROUTE_ADMIN_VENUES, userData, permissionsData) && (
              <Menu.Item
                onClick={() => handleClick(ROUTE_ADMIN_VENUES)}
                icon={
                  checkSubscriptionLevel("venues", orgData) ? null : (
                    <LockOutlined />
                  )
                }
                style={{
                  color: checkSubscriptionLevel("venues", orgData)
                    ? "white"
                    : "gray",
                }}
                key={ROUTE_ADMIN_VENUES}
              >
                {i18n("sidebar.admin.venues")}
              </Menu.Item>
            )}

            {hasPermission(ROUTE_ADMIN_TRUCKS, userData, permissionsData) && (
              <Menu.Item
                onClick={() => handleClick(ROUTE_ADMIN_TRUCKS)}
                icon={
                  checkSubscriptionLevel("trucks", orgData) ? null : (
                    <LockOutlined style={{ color: "gray" }} />
                  )
                }
                style={{
                  color: checkSubscriptionLevel("trucks", orgData)
                    ? "white"
                    : "gray",
                }}
                key={ROUTE_ADMIN_TRUCKS}
              >
                {i18n("sidebar.admin.trucks")}
              </Menu.Item>
            )}

            {hasPermission(
              ROUTE_ADMIN_FILE_MANAGER,
              userData,
              permissionsData
            ) && (
              <Menu.Item
                onClick={() => handleClick(ROUTE_ADMIN_FILE_MANAGER)}
                icon={
                  checkSubscriptionLevel("fileManager", orgData) ? null : (
                    <LockOutlined />
                  )
                }
                style={{
                  color: checkSubscriptionLevel("fileManager", orgData)
                    ? "white"
                    : "gray",
                }}
                key={ROUTE_ADMIN_FILE_MANAGER}
              >
                {"File Manager"}
              </Menu.Item>
            )}

            {hasPermission(
              ROUTE_ADMIN_INTEGRATIONS,
              userData,
              permissionsData
            ) && (
              <Menu.Item
                onClick={() => handleClick(ROUTE_ADMIN_INTEGRATIONS)}
                key={ROUTE_ADMIN_INTEGRATIONS}
              >
                {i18n("sidebar.admin.integrations")}
              </Menu.Item>
            )}

            {hasPermission(ROUTE_ADMIN_BILLING, userData, permissionsData) && (
              <Menu.Item
                onClick={() => handleClick(ROUTE_ADMIN_BILLING)}
                key={ROUTE_ADMIN_BILLING}
              >
                {"Billing"}
              </Menu.Item>
            )}
          </SubMenu>

          <SubMenu
            key="settingsSubMenu"
            icon={<SettingOutlined />}
            title={i18n("sidebar.settings.title")}
          >
            {hasPermission(
              ROUTE_ADMIN_SETTINGS_GENERAL,
              userData,
              permissionsData
            ) && (
              <Menu.Item
                onClick={() => handleClick(ROUTE_ADMIN_SETTINGS_GENERAL)}
                key={ROUTE_ADMIN_SETTINGS_GENERAL}
              >
                {i18n("sidebar.settings.general")}
              </Menu.Item>
            )}
            {hasPermission(
              ROUTE_ADMIN_SETTINGS_EMAIL,
              userData,
              permissionsData
            ) && (
              <Menu.Item
                onClick={() => handleClick(ROUTE_ADMIN_SETTINGS_EMAIL)}
                key={ROUTE_ADMIN_SETTINGS_EMAIL}
              >
                {i18n("sidebar.settings.email")}
              </Menu.Item>
            )}

            {hasPermission(
              ROUTE_ADMIN_SETTINGS_WORDS,
              userData,
              permissionsData
            ) && (
              <Menu.Item
                onClick={() => handleClick(ROUTE_ADMIN_SETTINGS_WORDS)}
                icon={
                  checkSubscriptionLevel(
                    "invoiceCustomization",
                    orgData
                  ) ? null : (
                    <LockOutlined />
                  )
                }
                style={{
                  color: checkSubscriptionLevel("invoiceCustomization", orgData)
                    ? "white"
                    : "gray",
                }}
                key={ROUTE_ADMIN_SETTINGS_WORDS}
              >
                Text Customization
              </Menu.Item>
            )}

            {hasPermission(
              ROUTE_ADMIN_SETTINGS_PERMISSIONS,
              userData,
              permissionsData
            ) && (
              <Menu.Item
                onClick={() => handleClick(ROUTE_ADMIN_SETTINGS_PERMISSIONS)}
                key={ROUTE_ADMIN_SETTINGS_PERMISSIONS}
              >
                User Permissions
              </Menu.Item>
            )}

            {orgData &&
              orgData.embeddedCheckoutEnabled &&
              hasPermission(
                ROUTE_ADMIN_EMBEDDED_CHECKOUT,
                userData,
                permissionsData
              ) && (
                <Menu.Item
                  onClick={() => handleClick(ROUTE_ADMIN_EMBEDDED_CHECKOUT)}
                  key={ROUTE_ADMIN_EMBEDDED_CHECKOUT}
                >
                  {i18n("sidebar.settings.embeddedCheckout")}
                </Menu.Item>
              )}
          </SubMenu>

          <Menu.Item
            onClick={() => handleClick(ROUTE_CHANGE_LOG)}
            key={ROUTE_CHANGE_LOG}
            icon={<FireOutlined />}
          >
            Change Log
          </Menu.Item>

          <Menu.Item
            onClick={() => handleClick(ROUTE_MOBILE_APPS)}
            key={ROUTE_MOBILE_APPS}
            icon={<MobileOutlined />}
          >
            Mobile Apps
          </Menu.Item>
        </Menu>
      </div>
    </Sider>
  ) : (
    <div></div>
  );
};

export default Sidebar;
