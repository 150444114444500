// Types
import { InvoiceTextData } from "../../admin-settings/components/words-invoice";
import { OrganizationData } from "../../../app/interfaces/organizationData";
import { Invoice } from "../../../app/interfaces/invoice";

import {
  Page,
  View,
  Text,
  Image,
  Document,
  Font,
  StyleSheet,
  Link
} from "@react-pdf/renderer";
import Html from "react-pdf-html";
import DOMPurify from "dompurify";

import { useMemo } from "react";

import { FormatToLocalCurrency } from "../../../app/utils/currency/formatToLocalCurrency";
import { checkUndefinedOrNull } from "../../../app/utils/models/checkers/checkUndefined";
import { NumberToPercentStr } from "../../../app/utils/Nifty/nifty";
import { fDate } from "../../../app/utils/format-time";

// ----------------------------------------------------------------------

Font.register({
  family: "Roboto",
  fonts: [
    // Thin
    { src: "/fonts/Roboto-Thin.ttf", fontWeight: 100 },
    {
      src: "/fonts/Roboto-ThinItalic.ttf",
      fontWeight: 100,
      fontStyle: "italic",
    },

    // Extra Light
    { src: "/fonts/Roboto-Light.ttf", fontWeight: 300 },
    {
      src: "/fonts/Roboto-LightItalic.ttf",
      fontWeight: 300,
      fontStyle: "italic",
    },

    // Regular
    { src: "/fonts/Roboto-Regular.ttf", fontWeight: 400 },
    { src: "/fonts/Roboto-Italic.ttf", fontWeight: 400, fontStyle: "italic" },

    // Medium
    { src: "/fonts/Roboto-Medium.ttf", fontWeight: 500 },
    {
      src: "/fonts/Roboto-MediumItalic.ttf",
      fontWeight: 500,
      fontStyle: "italic",
    },

    // Bold
    { src: "/fonts/Roboto-Bold.ttf", fontWeight: "bold" }, // 'bold' is equivalent to 700
    {
      src: "/fonts/Roboto-BoldItalic.ttf",
      fontWeight: "bold",
      fontStyle: "italic",
    },

    // Extra Bold
    { src: "/fonts/Roboto-Black.ttf", fontWeight: 900 },
    {
      src: "/fonts/Roboto-BlackItalic.ttf",
      fontWeight: 900,
      fontStyle: "italic",
    },
  ],
});

const useStyles = () =>
  useMemo(
    () =>
      StyleSheet.create({
        bold: {
          fontWeight: "bold",
        },
        col4: { width: "25%" },
        col8: { width: "75%" },
        col6: { width: "50%" },
        mb4: { marginBottom: 4 },
        mb8: { marginBottom: 8 },
        mb20: { marginBottom: 20 },
        mb40: { marginBottom: 40 },
        mt4: { marginTop: 4 },
        mt8: { marginTop: 8 },
        mt20: { marginTop: 20 },
        mt40: { marginTop: 40 },
        h3: { fontSize: 16, fontWeight: 700 },
        h4: { fontSize: 13, fontWeight: 700 },
        body1: { fontSize: 10 },
        body2: { fontSize: 9 },
        subtitle1: { fontSize: 10, fontWeight: 700 },
        subtitle2: { fontSize: 9, fontWeight: 700 },
        alignRight: { textAlign: "right" },
        page: {
          fontSize: 9,
          lineHeight: 1.6,
          fontFamily: "Roboto",
          backgroundColor: "#FFFFFF",
          textTransform: "capitalize",
          padding: "40px 24px 40px 24px",
        },
        footer: {
          left: 0,
          right: 0,
          bottom: 0,
          padding: 24,
          margin: "auto",
          borderTopWidth: 1,
          borderStyle: "solid",
          position: "absolute",
          borderColor: "#DFE3E8",
        },
        gridContainer: {
          flexDirection: "row",
          justifyContent: "space-between",
        },
        table: {
          display: "flex",
          width: "auto",
        },
        tableRow: {
          padding: "8px 0",
          flexDirection: "row",
          borderBottomWidth: 1,
          borderStyle: "solid",
          borderColor: "#DFE3E8",
        },
        tableRowTotals: {
          padding: "2px 0",
          flexDirection: "row",
          borderBottomWidth: 1,
          borderStyle: "solid",
          borderColor: "#DFE3E8",
        },
        noBorder: {
          paddingBottom: 0,
          borderBottomWidth: 0,
        },
        tableCell_5: {
          width: "5%",
        },
        tableCell_50: {
          width: "50%",
          paddingRight: 16,
        },
        tableCell_15: {
          width: "15%",
        },
        tableCell_image: {
          width: "5%",
        },
        tableCell_item: {
          width: "55%",
        },
        tableCell_qty: {
          width: "10%",
        },
        tableCell_rate: {
          width: "10%",
        },
        tableCell_duration: {
          width: "10%",
        },
        tableCell_rowtotal: {
          width: "10%",
        },
        spaces_row: {
          flexDirection: "row",
          justifyContent: "space-between",
          // marginBottom: 10,
        },
      }),
    []
  );

// ----------------------------------------------------------------------

type Props = {
  invoice: Invoice;
  orgData: OrganizationData;
  venues: any[];
  emailSettings: any;
  customText: InvoiceTextData;
  i18n: any;
  logoData: string;
  itemImages: [{ id: string; data: string }];
  signatureData: { data: string; date: any } | null;
};

export default function InvoicePDF({
  invoice,
  orgData,
  venues,
  emailSettings,
  customText,
  i18n,
  logoData,
  itemImages,
  signatureData,
}: Props) {
  const { selectedItems: items, invoiceNumber } = invoice;

  const styles = useStyles();

  let additionalWidth = 0;
  if (!orgData.showRate) {
    additionalWidth += 10;
  }
  if (!orgData.showInvoiceItemImg) {
    additionalWidth += 5;
  }
  if (!orgData.showDuration) {
    additionalWidth += 10;
  }
  if (additionalWidth > 0) {
    styles.tableCell_item.width = `${55 + additionalWidth}%`;
  }

  const itemImageSize = customText.itemImageSize === "small" ? "30px" : "50px";
  let orgLogoWidth = 150; // default to large square
  let orgLogoHeight = 150; // default to large square

  if (
    customText.logoSize === "small" &&
    customText.logoDimension === "square"
  ) {
    orgLogoWidth = 100;
    orgLogoHeight = 100;
  }
  if (customText.logoDimension === "rectangle") {
    orgLogoHeight = 75;
  }

  const placeholderImage =
    "https://firebasestorage.googleapis.com/v0/b/adelie-logistics-production.appspot.com/o/CleanShot%202023-12-07%20at%2008.10.54%402x.png?alt=media&token=adcc6fa9-ffb3-4375-bc93-35c36ad69d93";

    
  const preferredDateStyle =
    customText.preferredDateStyle || "EEE, dd MMM yyyy";
  const hasTotalDiscount = invoice?.hasTotalDiscount;
  const totalDiscountType = invoice?.totalDiscountType;
  const totalDiscountPercent = invoice?.totalDiscountPercent; // Percentage of discount
  const totalDiscountNumber = invoice?.totalDiscountNumber; // Amount of discount

  const discountString = () => {
    if (hasTotalDiscount && hasTotalDiscount) {
      if (totalDiscountType === "number") {
        return FormatToLocalCurrency(
          checkUndefinedOrNull(totalDiscountNumber, 0),
          orgData
        );
      } else if (totalDiscountType === "percent") {
        return NumberToPercentStr(totalDiscountPercent, 2);
      }
      return "$0.00";
    } else {
      return "$0.00";
    }
  };

  const receiveVenueID = invoice.receiveVenueQueryHook;
  const returnVenueID = invoice.returnVenueQueryHook;

  const receiveVenue = venues?.find((venue) => venue.id === receiveVenueID);
  const returnVenue = venues?.find((venue) => venue.id === returnVenueID);

  const formatReceiveTimeRange = (invoice: Invoice) => {
    const options = { hour: "numeric", minute: "numeric" };

    let timeRange = "";
    if (invoice?.hasSpecifiedReceiveTime) {
      if (invoice?.specifiedReceiveTime?.toDate()) {
        const startTime = invoice?.specifiedReceiveTime
          ?.toDate()
          ?.toLocaleTimeString(undefined, options);
        timeRange = startTime;

        if (invoice?.specifiedReceiveTimeEnd) {
          const endTime = invoice?.specifiedReceiveTimeEnd
            ?.toDate()
            ?.toLocaleTimeString(undefined, options);
          timeRange = timeRange + ` - ${endTime}`;
        }
      }

      return timeRange;
    } else {
      return i18n.common.noneSpecified || "None Specified";
    }
  };

  const formatReturnTimeRange = (invoice: Invoice) => {
    const options = { hour: "numeric", minute: "numeric" };

    let timeRange = "";
    if (invoice?.hasSpecifiedReturnTime) {
      const startTime = invoice.specifiedReturnTime
        ?.toDate()
        ?.toLocaleTimeString(undefined, options);
      timeRange = startTime;

      if (invoice?.specifiedReturnTimeEnd) {
        const endTime = invoice?.specifiedReturnTimeEnd
          ?.toDate()
          ?.toLocaleTimeString(undefined, options);
        timeRange = timeRange + ` - ${endTime}`;
      }

      return timeRange;
    } else {
      return i18n.common.noneSpecified || "None Specified";
    }
  };

  /*
  logoSize      // large / small
  logoDimension // square / rectangle
  itemImageSize // large () / small 
  */

  const receiveReturnDetails = () => {
    return (
      <View style={[styles.mt8, styles.mb8]}>
        <View
          style={[
            styles.gridContainer,
            {
              backgroundColor: "#ededed",
              padding: "4px",
              marginBottom: "4px",
            },
          ]}
        >
          <Text style={styles.bold}>{customText.receiveReturnDetails}:</Text>
        </View>

        <View style={[styles.gridContainer, { padding: "4px" }]}>
          <View style={styles.col6}>
            <Text>
              <Text style={styles.bold}>
                {customText.customerReceivesItems}:{" "}
              </Text>
              {invoice.receiveMethod === "delivery"
                ? i18n.pdf.delivery || "Delivery"
                : i18n.pdf.customerPickup || "Customer Pickup"}
            </Text>

            <Text style={{ marginTop: "4px" }}>
              <Text style={styles.bold}>{customText.receiveAddress}:</Text>
              {"\n"}
              {invoice.receiveMethod === "delivery"
                ? invoice.deliveryCustomerShippingAddressStr
                : orgData.companyAddress}
            </Text>

            {receiveVenueID && receiveVenue && (
              <View style={[styles.spaces_row, { marginTop: "4px" }]}>
                <Text>
                  <Text style={styles.bold}>
                    {customText.venue || "Venue"}:
                  </Text>
                  {"\n"}
                  {receiveVenue.venueName}
                  {"\n"}
                  {i18n.pdf.contact || "Contact"}:{" "}
                  {invoice.deliveryCustomerDisplayName}
                </Text>
              </View>
            )}

            <Text style={[styles.spaces_row, { marginTop: "4px" }]}>
              <Text style={styles.bold}>
                {i18n.pdf.timeRange || "Date/Time(Range)"}:
              </Text>
              {"\n"}
              {fDate(invoice.rentalDateStart.toDate(), preferredDateStyle)}
              {"\n"}
              {formatReceiveTimeRange(invoice)}
            </Text>
          </View>

          <View style={styles.col6}>
            <Text>
              <Text style={styles.bold}>{customText.itemsReturnedBy}: </Text>
              {invoice.returnMethod === "onSite"
                ? i18n.pdf.pickup || "Pickup"
                : i18n.pdf.customerDropOff || "Customer Dropoff"}
            </Text>

            <Text style={{ marginTop: "4px" }}>
              <Text style={styles.bold}>{customText.receiveAddress}:</Text>
              {"\n"}
              {invoice.returnMethod === "onSite"
                ? invoice.onSiteCustomerShippingAddressStr
                : orgData.companyAddress}
            </Text>

            {returnVenue && returnVenueID && (
              <Text style={{ marginTop: "4px" }}>
                <Text style={styles.bold}>{customText.venue || "Venue"}:</Text>
                {"\n"}
                {returnVenue.venueName}
                {"\n"}
                {i18n.pdf.contact || "Contact"}:{" "}
                {invoice.onSiteCustomerDisplayName}
              </Text>
            )}

            <Text style={{ marginTop: "4px" }}>
              <Text style={styles.bold}>
                {i18n.pdf.timeRange || "Date/Time(Range)"}:
              </Text>
              {"\n"}
              {fDate(invoice.rentalDateEnd.toDate(), preferredDateStyle)}
              {"\n"}
              {formatReturnTimeRange(invoice)}
            </Text>
          </View>
        </View>
      </View>
    );
  };

    const notesView = () => {
    return (
      
      <View style={[styles.mt8, styles.mb8]}>
        <Text style={styles.bold}>{i18n.pdf.additionalNotes || "Notes"}</Text>

        <Html style={{ fontSize: 8, fontFamily: "Roboto" }}>
            {
              emailSettings?.invoiceNotes
                .replaceAll("<p><br></p>", "<br>")
                .replaceAll("<p>", "<br>")
                .replaceAll("</p>", "")
            }
          </Html>

          <Text style={styles.body2}>-----------------------------</Text>

          <Text style={styles.body2}>{invoice.notes}</Text>
      </View>
    );
  };

  const signatureView = () => {
    return (
      <View
        fixed
        style={{
          position: "absolute",
          bottom: 0,
          left: 20,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          width: "100%",
          height: "auto",
          padding: "2px",
          backgroundColor: "#ededed",
          borderStyle: "solid",
          borderColor: "#DFE3E8",
          borderWidth: 1,
        }}
      >
        <View style={{ paddingRight: "6px" }}>
          <Text style={{ fontSize: 8 }}>Signed:</Text>
          <Text style={{ fontSize: 8 }}>{fDate(signatureData?.date)}</Text>
        </View>
        <Image
          style={{
            width: "auto",
            height: "30px",
            objectFit: "scale-down",
          }}
          src={signatureData?.data}
        />
      </View>
    );
  };

  return (
    <Document>
      <Page wrap size="A4" style={styles.page}>
        {signatureData?.data && signatureView()}

        <View style={[styles.gridContainer, styles.mb20]}>
          {orgData.logoUrl && (
            <View
              style={{
                height: orgLogoHeight,
                width: orgLogoWidth,
                marginRight: "8px",
                flexDirection: "row",
                alignItems: "flex-start",
              }}
            >
              {orgData.logoUrl && (
                <Image
                  style={{
                    width: "auto",
                    height: "auto",
                    objectFit: "scale-down",
                  }}
                  src={logoData}
                />
              )}
            </View>
          )}

          <View style={{ alignItems: "flex-end", flexDirection: "column" }}>
            <Text style={styles.h3}>{invoice.type === "invoice" ? customText.invoice || "Invoice" : customText.estimate || "Estimate"}</Text>
            <Text> #{invoiceNumber} </Text>
            <Text>
              {" "}
              {customText.invoiceUpdated}: {fDate(invoice.updatedOn.toDate(), preferredDateStyle)}
            </Text>
            <Text>
              {" "}
              {customText.invoiceDue}: {fDate(invoice.invoicePaymentDueDate.toDate(), preferredDateStyle)}
            </Text>
            <Text>
              {" "}
              {customText.invoiceRentalStart}: {fDate(invoice.rentalDateStart.toDate(), preferredDateStyle)}
            </Text>
            <Text>
              {" "}
              {customText.invoiceRentalEnd}: {fDate(invoice.rentalDateEnd.toDate(), preferredDateStyle)}
            </Text>
            {invoice.eventDate && (
              <Text>
                {" "}
                {customText.invoiceEventDate}: {fDate(invoice.eventDate?.toDate(), preferredDateStyle)}
              </Text>
            )}
          </View>
        </View>

        <View style={[styles.gridContainer, styles.mb20]}>
          <View style={styles.col6}>
            <Text style={[styles.subtitle2, styles.mb4]}>From</Text>
            <Text style={styles.body2}>{orgData.companyName}</Text>

            {orgData.showCompanyAddress && orgData.showCompanyAddress === true && <Text style={styles.body2}>{orgData.companyAddress}</Text>}
            <Text style={styles.body2}>{orgData.companyPhone}</Text>
          </View>

          <View style={styles.col6}>
            <Text style={[styles.subtitle2, styles.mb4]}>To</Text>
            <Text style={styles.body2}>{invoice?.customer?.customerDisplayName}</Text>
            <Text style={styles.body2}>{invoice?.customer?.customerBillingAddressStr}</Text>
            {invoice?.customer?.phone && <Text style={styles.body2}> {invoice?.customer?.phone}</Text>}
            {invoice?.customer?.email && <Text style={styles.body2}> {invoice?.customer?.email}</Text>}
          </View>
        </View>

        {customText.notesPosition === "above" && (emailSettings?.invoiceNotes?.length > 0 || invoice.notes.length > 0) && notesView()}

        {customText.receiveDetailsPosition === "above" && invoice && !invoice.isSalesInvoice && receiveReturnDetails()}

        <View style={styles.table}>
          <View>
            <View
              style={[
                styles.tableRow,
                {
                  backgroundColor: "#ededed",
                  padding: "4px",
                },
                {
                  borderBottomWidth: 0,
                },
              ]}
            >
              {orgData.showInvoiceItemImg && (
                <View style={styles.tableCell_image}>
                  <Text style={styles.subtitle2}> </Text>
                </View>
              )}

              <View style={styles.tableCell_item}>
                <Text style={styles.subtitle2}>{i18n.pdf.columnItem || "Item"}</Text>
              </View>

              <View style={styles.tableCell_qty}>
                <Text style={styles.subtitle2}>{i18n.pdf.colQty || "Qty"}</Text>
              </View>

              {orgData.showRate && (
                <View style={styles.tableCell_rate}>
                  <Text style={styles.subtitle2}>{i18n.pdf.rate || "Rate"}</Text>
                </View>
              )}

              {orgData.showDuration && (
                <View style={styles.tableCell_duration}>
                  <Text style={styles.subtitle2}>{i18n.pdf.duration || "Duration"}</Text>
                </View>
              )}

              <View style={[styles.tableCell_rowtotal, styles.alignRight]}>
                <Text style={styles.subtitle2}>{i18n.pdf.total || "Total"}</Text>
              </View>
            </View>
          </View>

          <View>
            {items.items
              .filter((i) => i)
              .map((item, index) => {
                if (item.rowType === "subtotal") {
                  return (
                    <View style={[styles.tableRow, styles.spaces_row]} key={index}>
                      <Text style={styles.subtitle2}>{i18n.pdf.subtotal || "Subtotal"}</Text>
                      <Text>{item.selectedDescription}</Text>
                      <Text>{FormatToLocalCurrency(item.rowTotal, orgData)}</Text>
                    </View>
                  );
                } else if (item.rowType === "damageWaiver") {
                  return (
                    <View style={[styles.tableRow, styles.spaces_row]} key={index}>
                      <Text style={styles.subtitle2}>{i18n.pdf.damageWaiver || "Damage Waiver"}</Text>
                      <Text>{item.selectedDescription}</Text>
                      <Text>{FormatToLocalCurrency(item.rowTotal, orgData)}</Text>
                    </View>
                  );
                } else if (item.rowType === "flatFee") {
                  return (
                    <View style={[styles.tableRow, styles.spaces_row]} key={index}>
                      <Text style={styles.subtitle2}>{item.selectedDescription}</Text>
                      <Text>{FormatToLocalCurrency(item.rowTotal, orgData)}</Text>
                    </View>
                  );
                } else if (item.rowType === "descriptionOnly") {
                  return (
                    <View style={[styles.tableRow, styles.spaces_row]} key={index}>
                      <Text style={styles.subtitle2}>{item.selectedDescription}</Text>
                    </View>
                  );
                } else if (item.rowType === "percentFee") {
                  return (
                    <View style={[styles.tableRow, styles.spaces_row]} key={index}>
                      <Text style={styles.subtitle2}>{item.selectedDescription}</Text>
                      <Text>{FormatToLocalCurrency(item.rowTotal, orgData)}</Text>
                    </View>
                  );
                } else if (item.rowType === "discount") {
                  return (
                    <View style={[styles.tableRow, styles.spaces_row]} key={index}>
                      <Text style={styles.subtitle2}>{item.selectedDescription}</Text>
                      <Text>{FormatToLocalCurrency(item.rowTotal, orgData)}</Text>
                    </View>
                  );
                } else {
                  return (
                    <View style={styles.tableRow} key={index}>
                      <>
                        {orgData.showInvoiceItemImg && item?.image && (
                          <View
                            style={[
                              styles.tableCell_image,
                              {
                                height: itemImageSize,
                                width: itemImageSize,
                                marginRight: "8px",
                              },
                            ]}
                          >
                            <Image
                              style={{
                                width: "100%",
                                height: "auto",
                                objectFit: "scale-down",
                              }}
                              src={`${itemImages.find((imgItem) => imgItem.id === item.id)?.data}`}
                            />
                          </View>
                        )}

                        {orgData.showInvoiceItemImg && !item?.image && (
                          <View
                            style={[
                              styles.tableCell_image,
                              {
                                height: "10",
                                width: itemImageSize,
                                marginRight: "8px",
                              },
                            ]}
                          />
                        )}
                      </>
                      <View style={styles.tableCell_item}>
                        {orgData.showItemName &&
                          (item.informationURL ? (
                            <Link src={item.informationURL}>
                              <Text style={styles.subtitle2}>{item.name}</Text>
                            </Link>
                          ) : (
                            <Text style={styles.subtitle2}>{item.name}</Text>
                          ))}
                        {orgData.showDescription && <Text>{item.selectedDescription}</Text>}
                      </View>

                      <View style={[styles.tableCell_qty, { paddingLeft: "5px" }]}>
                        <Text>{item.selectedQty}</Text>
                      </View>

                      {orgData.showRate && (
                        <View style={styles.tableCell_rate}>
                          <Text>{item?.selectedRateMeta?.rate && FormatToLocalCurrency(item.selectedRateMeta.rate, orgData)}</Text>
                        </View>
                      )}

                      {orgData.showDuration && (
                        <View style={styles.tableCell_duration}>
                          <Text>
                            {item?.selectedDuration && item.selectedDuration}{" "}
                            {item?.selectedRateMeta?.rateRange && item.selectedDuration === 1
                              ? item.selectedRateMeta?.rateRange
                              : `${item.selectedRateMeta?.rateRange}s`}
                          </Text>
                        </View>
                      )}

                      {orgData.showTotal && (
                        <View style={[styles.tableCell_rowtotal, styles.alignRight]}>
                          <Text>{FormatToLocalCurrency(checkUndefinedOrNull(item.rowTotal, 0), orgData)}</Text>
                        </View>
                      )}
                    </View>
                  );
                }
              })}

            {invoice.deliveryFee && invoice.deliveryFee > 0 && (
              <View style={[styles.tableRowTotals, styles.noBorder]}>
                <View style={styles.tableCell_5} />
                <View style={styles.tableCell_50} />
                <View style={styles.tableCell_15} />
                <View style={styles.tableCell_15}>
                  <Text>{customText.deliveryFee}</Text>
                </View>
                <View style={[styles.tableCell_15, styles.alignRight]}>
                  <Text>{FormatToLocalCurrency(checkUndefinedOrNull(invoice.deliveryFee, 0), orgData)}</Text>
                </View>
              </View>
            )}

            {hasTotalDiscount && (
              <View style={[styles.tableRowTotals, styles.noBorder]}>
                <View style={styles.tableCell_5} />
                <View style={styles.tableCell_50} />
                <View style={styles.tableCell_15} />
                <View style={styles.tableCell_15}>
                  <Text>{i18n.pdf.discount || "Discount"}</Text>
                </View>
                <View style={[styles.tableCell_15, styles.alignRight]}>
                  <Text>- {discountString()}</Text>
                </View>
              </View>
            )}

            <View style={[styles.tableRowTotals, styles.noBorder, { marginTop: "6px" }]}>
              <View style={styles.tableCell_5} />
              <View style={styles.tableCell_50} />
              <View style={styles.tableCell_15} />
              <View style={styles.tableCell_15}>
                <Text>{i18n.pdf.subtotal || "Subtotal"}</Text>
              </View>
              <View style={[styles.tableCell_15, styles.alignRight]}>
                <Text>{FormatToLocalCurrency(checkUndefinedOrNull(invoice.subtotal, 0), orgData)}</Text>
              </View>
            </View>

            <View style={[styles.tableRowTotals, styles.noBorder]}>
              <View style={styles.tableCell_5} />
              <View style={styles.tableCell_50} />
              <View style={styles.tableCell_15} />
              <View style={styles.tableCell_15}>
                <Text>{i18n.pdf.taxes || "Taxes"}</Text>
              </View>
              <View style={[styles.tableCell_15, styles.alignRight]}>
                <Text>{FormatToLocalCurrency(checkUndefinedOrNull(invoice.taxAmt, 0), orgData)}</Text>
              </View>
            </View>

            <View style={[styles.tableRowTotals, styles.noBorder]}>
              <View style={styles.tableCell_5} />
              <View style={styles.tableCell_50} />
              <View style={styles.tableCell_15} />
              <View style={styles.tableCell_15}>
                <Text style={styles.h4}>{i18n.pdf.total || "Total"}</Text>
              </View>
              <View style={[styles.tableCell_15, styles.alignRight]}>
                <Text style={styles.h4}>{FormatToLocalCurrency(checkUndefinedOrNull(invoice.total, 0), orgData)}</Text>
              </View>
            </View>

            <View style={[styles.tableRowTotals, styles.noBorder]}>
              <View style={styles.tableCell_5} />
              <View style={styles.tableCell_50} />
              <View style={styles.tableCell_15} />
              <View style={styles.tableCell_15}>
                <Text>{i18n.pdf.amountPaid || "Amount Paid"}</Text>
              </View>
              <View style={[styles.tableCell_15, styles.alignRight]}>
                <Text>{FormatToLocalCurrency(invoice?.balanceFulfilled, orgData)}</Text>
              </View>
            </View>

            <View style={[styles.tableRowTotals, styles.noBorder]}>
              <View style={styles.tableCell_5} />
              <View style={styles.tableCell_50} />
              <View style={styles.tableCell_15} />
              <View style={styles.tableCell_15}>
                <Text>{i18n.pdf.remainingBalance || "Remaining Balance"}</Text>
              </View>
              <View style={[styles.tableCell_15, styles.alignRight]}>
                <Text>{FormatToLocalCurrency(invoice?.balanceRemaining, orgData)}</Text>
              </View>
            </View>
          </View>
        </View>

        {customText.receiveDetailsPosition === "below" && invoice && !invoice.isSalesInvoice && receiveReturnDetails()}

        {customText.notesPosition === "below" && (emailSettings?.invoiceNotes?.length > 0 || invoice.notes.length > 0) && notesView()}

        <View style={{ marginTop: "8px" }}>
          <Html style={{ fontSize: 8, fontFamily: "Roboto" }}>
            {emailSettings?.termsConditions.replaceAll("<p><br></p>", "<br>").replaceAll("<p>", "<br>").replaceAll("</p>", "")}
          </Html>
        </View>
      </Page>
    </Document>
  );
}
