import { useEffect, useState } from "react";
import { Space, Typography } from "antd";
import { FormatToLocalCurrency } from "../../app/utils/currency/formatToLocalCurrency";
import { useSelector } from "react-redux";
import { authSelector } from "../auth/authSlice";
import { IconButton, Tooltip } from "@mui/material";
import Iconify from "../../app/iconify";
import { useFirebase } from "react-redux-firebase";
import notificationConfirm from "../../app/system-components/toasters/notificationConfirm";

/**
 * Takes customer object and displays balance and credit
 */
const { Text } = Typography;
const DisplayCustomerBalance = ({ customer, direction }) => {

  const firebase = useFirebase();
  const { orgData } = useSelector(authSelector);

  const spaceDirection = configureDirection(direction);
  const [credit, setCredit] = useState('');
  const [balance, setBalance] = useState('');
  const [isRecalculating, setIsRecalculating] = useState(false);

  useEffect(() => {
    setCredit(FormatToLocalCurrency(customer?.credit, orgData))
    setBalance(FormatToLocalCurrency(customer?.balance, orgData))
  }, [orgData, customer]);

  return (
    <Space direction={spaceDirection}>
      <Tooltip title="Balance not looking right? Click to recalculate.">
        <IconButton
          onClick={() => {
            setIsRecalculating(true);
            const call = firebase.functions().httpsCallable("customerManager");
            return call({
              action: "updateCustomerBalance",
              orgId: orgData?.id,
              data: {
                customerId: customer?.id,
              },
            })
              .then((res) => {
                const newBalance = res.data.customerBalance;
                setBalance(FormatToLocalCurrency(newBalance / 100, orgData));
                notificationConfirm("Balance updated successfully");
              })
              .catch((err) => {
                console.log(err);
                notificationConfirm("Error updating balance", "error");
              })
              .finally(() => {
                setIsRecalculating(false);
              });
          }}
          size="small"
          disabled={isRecalculating}
        >
          <Iconify icon={ isRecalculating ? "line-md:loading-loop" : "mdi:refresh" } />
        </IconButton>
      </Tooltip>

      <Text>
        Balance: <span style={{ fontWeight: "bold" }}>{balance}</span>
      </Text>
      <Text>
        Credit: <span style={{ fontWeight: "bold" }}>{credit}</span>
      </Text>
    </Space>
  );
};

export default DisplayCustomerBalance;

const configureDirection = (direction) => {
  if (!direction || direction === "horizontal") return "horizontal";
  if (direction === "vertical") return "vertical";
  return "horizontal";
};
