import { checkUndefined, checkUndefinedCurrency, checkUndefinedSelectedItems } from './checkUndefined';
import { QR_STATUS_IN_PROGRESS, RECEIVE_METHOD_DELIVERY, RETURN_METHOD_ONSITE } from '../modelConstants/modelConstants';
import { addOrgUtcOffsetToInvoice } from '../configure/wrapper/addOrgUtcOffsetToInvoice';
import { DocumentData, QueryDocumentSnapshot, DocumentSnapshot } from '@firebase/firestore-types';
import { select } from '../../../theme/overrides/components/select';

export const checkInvoices = (snap: QueryDocumentSnapshot<DocumentData> | DocumentSnapshot<DocumentData>, orgTimezone: string) => {
  if (!snap) return;
  const data = snap.data();
  if (!data) return;

  const calcBalance = (invoiceData: DocumentData) => {
    const balanceFulfilled = checkUndefinedCurrency(invoiceData.balanceFulfilled, 0);
    const total = checkUndefinedCurrency(invoiceData.total, 0);
    const balance = total - balanceFulfilled;
    return {
      balance,
      isComplete: balance <= 0,
    };
  };

  const emailDeliveryFailed = data.emailDeliveryFailed;

  const invoiceData = {
    receiveVenueQueryHook: checkUndefined(data.receiveVenueQueryHook, null),
    returnVenueQueryHook: checkUndefined(data.returnVenueQueryHook, null),
    additionalContactId: checkUndefined(data.additionalContactId, null),
    bundleItemsQueryHook: checkUndefined(data.bundleItemsQueryHook, []),
    categoriesQueryHook: checkUndefined(data.categoriesQueryHook, []),
    createdBy: checkUndefined(data.createdBy, null),
    createdOn: checkUndefined(data.createdOn, null),
    customer: checkUndefined(data.customer, null),
    customerQueryHook: checkUndefined(data.customerQueryHook, ''),
    customerAddressChecked: checkUndefined(data.customerAddressChecked, {
      receive: true,
      return: true,
    }),
    contactPhoneNumber: checkUndefined(data.contactPhoneNumber, ''),
    selectedContact: checkUndefined(data.selectedContact, null),
    deliveryCustomerDisplayName: checkUndefined(data.deliveryCustomerDisplayName, ''),
    deliveryCustomerShippingAddress: checkUndefined(data.deliveryCustomerShippingAddress, ''),
    deliveryCustomerShippingAddressStr: checkUndefined(data.deliveryCustomerShippingAddressStr, ''),
    deliveryCustomerShippingCity: checkUndefined(data.deliveryCustomerShippingCity, ''),
    deliveryCustomerShippingState: checkUndefined(data.deliveryCustomerShippingState, ''),
    deliveryCustomerShippingZip: checkUndefined(data.deliveryCustomerShippingZip, ''),
    hasSpecifiedReceiveTime: checkUndefined(data.hasSpecifiedReceiveTime, false),
    hasSpecifiedReturnTime: checkUndefined(data.hasSpecifiedReturnTime, false),
    hasTotalDiscount: checkUndefined(data.hasTotalDiscount, false),
    id: checkUndefined(data.id, ''),
    invoiceNumber: checkUndefined(data.invoiceNumber, null),
    invoiceSource: checkUndefined(data.invoiceSource, 'adelie'),
    invoicePaymentDueDate: checkUndefined(data.invoicePaymentDueDate, null),
    notes: checkUndefined(data.notes, ''),
    internalNotes: checkUndefined(data.internalNotes, ''),
    onSiteCustomerDisplayName: checkUndefined(data.onSiteCustomerDisplayName, ''),
    onSiteCustomerShippingAddress: checkUndefined(data.onSiteCustomerShippingAddress, ''),
    onSiteCustomerShippingAddressStr: checkUndefined(data.onSiteCustomerShippingAddressStr, ''),
    onSiteCustomerShippingCity: checkUndefined(data.onSiteCustomerShippingCity, ''),
    onSiteCustomerShippingState: checkUndefined(data.onSiteCustomerShippingState, ''),
    onSiteCustomerShippingZip: checkUndefined(data.onSiteCustomerShippingZip, ''),
    orgId: checkUndefined(data.orgId, null),
    qrStatus: checkUndefined(data.qrStatus, QR_STATUS_IN_PROGRESS),
    qrTotal: checkUndefined(data.qrTotal, 0),
    qrInvoiceHasIssues: checkUndefined(data.qrInvoiceHasIssues, false),
    qrItems: checkUndefined(data.qrItems, []),
    receiveMethod: checkUndefined(data.receiveMethod, RETURN_METHOD_ONSITE),
    rentalDates: checkUndefined(data.rentalDates, []),
    rentalRange: checkUndefined(data.rentalRange, 0),
    rentalDateStart: checkUndefined(data.rentalDateStart, null),
    rentalDateEnd: checkUndefined(data.rentalDateEnd, null),
    returnMethod: checkUndefined(data.returnMethod, RECEIVE_METHOD_DELIVERY),
    rentalTurnaroundEnd: checkUndefined(data.rentalTurnaroundEnd, null),
    selectedItems: checkUndefinedSelectedItems(data.selectedItems, {
      items: [],
      count: 0,
    }),
    mobileStartDate: checkUndefined(data.mobileStartDate, null),
    mobileEndDate: checkUndefined(data.mobileEndDate, null),
    selectedItemsQueryHook: checkUndefined(data.selectedItemsQueryHook, []),
    specifiedReceiveTime: checkUndefined(data.specifiedReceiveTime, null),
    specifiedReturnTime: checkUndefined(data.specifiedReturnTime, null),
    specifiedReceiveTimeEnd: checkUndefined(data.specifiedReceiveTimeEnd, null),
    specifiedReturnTimeEnd: checkUndefined(data.specifiedReturnTimeEnd, null),
    subtotal: checkUndefinedCurrency(data.subtotal, 0),
    status: checkUndefined(data.status, null),
    taxAmt: checkUndefinedCurrency(data.taxAmt, 0),
    taxRate: checkUndefined(data.taxRate, 0),
    taxMetaData: checkUndefined(data.taxMetaData, null),
    total: checkUndefinedCurrency(data.total, 0),
    totalDiscountNumber: checkUndefinedCurrency(data.totalDiscountNumber, 0),
    totalDiscountPercent: checkUndefined(data.totalDiscountPercent, 0),
    totalDiscountType: checkUndefined(data.totalDiscountType, null),
    type: checkUndefined(data.type, null),
    updatedBy: checkUndefined(data.updatedBy, null),
    updatedOn: checkUndefined(data.updatedOn, null),
    deliveryAddrValidated: checkUndefined(data.deliveryAddrValidated, false),
    deliveryLat: checkUndefined(data.deliveryLat, 0),
    deliveryLng: checkUndefined(data.deliveryLng, 0),
    onSiteAddrValidated: checkUndefined(data.onSiteAddrValidated, false),
    onSiteLat: checkUndefined(data.onSiteLat, 0),
    onSiteLng: checkUndefined(data.onSiteLng, 0),
    signatureImgUrl: checkUndefined(data.signatureImgUrl, ''),
    deliveryFee: checkUndefinedCurrency(data.deliveryFee, 0),
    deliveryFeeTaxable: checkUndefined(data.deliveryFeeTaxable, true),
    lastSyncDate: checkUndefined(data.lastSyncDate, null),
    lastSyncSuccess: checkUndefined(data.lastSyncSuccess, null),
    balanceFulfilled: checkUndefinedCurrency(data.balanceFulfilled, 0), // do not send back up to FS
    balanceRemaining: calcBalance(data).balance, // do not send back up to FS
    paymentComplete: calcBalance(data).isComplete, // do not send back up to FS
    qboInvoiceNumber: checkUndefined(data.qboInvoiceNumber, null), // do not send back up to FS
    qbId: checkUndefined(data.qbId, null), // do not send back up to FS
    estimateSignatures: checkUndefined(data.estimateSignatures, []), // do not send back up to FS
    paymentSignatures: checkUndefined(data.paymentSignatures, []), // do not send back up to FS
    emailDeliveryFailed: checkUndefined(emailDeliveryFailed, false),
    hasSendRecords: checkUndefined(data.hasSendRecords, false),
    isSalesInvoice: checkUndefined(data.isSalesInvoice, false),
    internalFiles: checkUndefined(data.internalFiles, []),
    isLoaded: checkUndefined(data.isLoaded, false),
    receivedByDelivery: checkUndefined(data.receivedByDelivery, false),
    returnedByPickup: checkUndefined(data.returnedByPickup, false),
    receivedByPickup: checkUndefined(data.receivedByPickup, false),
    returnedByDropOff: checkUndefined(data.returnedByDropOff, false),
    salesRepName: checkUndefined(data.salesRepName, null),
    salesRepId: checkUndefined(data.salesRepId, null),
    tagsQueryHook: checkUndefined(data.tagsQueryHook, []),
    isSigned: checkUndefined(data.isSigned, false),
    isDeleted: checkUndefined(data.isDeleted, false),
    eventDate: checkUndefined(data.eventDate, null),
    totalWeight: checkUndefined(data.totalWeight, null),
    defaultCustomerDiscountOverridden: checkUndefined(data.defaultCustomerDiscountOverridden, false),
    madeByKit: checkUndefined(data.madeByKit, false),
    originalData: checkUndefined(data.originalData, null),
    contractVersion: checkUndefined(data.contractVersion, 1),
  };
  
  return addOrgUtcOffsetToInvoice({
    invoice: invoiceData,
    orgTimezone: orgTimezone,
  }).onRead();
};
