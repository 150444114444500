// src/app/services/hooks/useInvoiceNumberListener.ts
import { useDispatch, useSelector } from "react-redux"
import { useFirestore } from "react-redux-firebase"
import { useEffect, useState } from "react"
import { ORG_COLLECTION } from "../../utils/models/collections/collectionConstants"
import { authSelector } from "../../../domains/auth/authSlice"

export function useInvoiceNumberListener() {
  
  const firestore = useFirestore()
  const [currentInvoiceNumber, setCurrentInvoiceNumber] = useState<number | undefined>()
  const [nextInvoiceNumber, setNextInvoiceNumber] = useState<number>(1) // Enforce number type

  const { userData } = useSelector(authSelector)

  const listener =
    userData &&
    firestore
      .collection(ORG_COLLECTION)
      .doc(userData?.orgId)
      .collection('invoices')
      .orderBy('invoiceNumber', 'desc')
      .limit(1)
      .onSnapshot({}, (query) => {
        const latestInvoice = query.docs[0]

        if (latestInvoice) {
          const currentNumber = Number(latestInvoice.data().invoiceNumber) || 0
          setCurrentInvoiceNumber(currentNumber)

          if (currentNumber) {
            setNextInvoiceNumber(currentNumber + 1)
          } 
        } else {
          setCurrentInvoiceNumber(0)
          setNextInvoiceNumber(1)
        }
        
        // TODO: Need to check qbo for duplicate invoice numbers.
        
      })

  useEffect(() => {
    return function cleanup() {
      listener && listener()
    }
  }, [])

  return { currentInvoiceNumber, nextInvoiceNumber }
}